import Vue from 'vue'
import Vuex from 'vuex'
import {AfsVuexNamespaces, Status} from '@/constants/state'
import packetAssignment from '@/store/packetAssignment'
import survey from '@/store/survey'
import userInfo from '@/store/user'
import snackstand from '@/store/snackstand'
import general from '@/store/general'
import preferences from '@/store/preferences'

Vue.use(Vuex)

const mutatioss = {
    [Status.CLEAR.setter](){}
}

const actions = {
    async [Status.CLEAR.updater]({commit, dispatch, }, payload){
        const promises = Object.values(AfsVuexNamespaces).map(x => {
            return dispatch(`${x.mapKey}${Status.CLEAR.updater}`, payload)
        })
        await Promise.all(promises)
        // dispatch(`${AfsVuexNamespaces.Surveys.mapKey}${Status.CLEAR.updater}`, payload)
        // dispatch(`${AfsVuexNamespaces.User.mapKey}${Status.CLEAR.updater}`, payload)
        // dispatch(`${AfsVuexNamespaces.Assignments.mapKey}${Status.CLEAR.updater}`, payload)
        // dispatch(`${AfsVuexNamespaces.Snackstand.mapKey}${Status.CLEAR.updater}`, payload)
        commit(Status.CLEAR.setter, payload)
    }
}

export default new Vuex.Store({
    state: {
        nopProp: 1,
    },
    mutations: mutatioss,
    actions: actions,
    modules: {
        [AfsVuexNamespaces.Assignments]: packetAssignment,
        [AfsVuexNamespaces.Surveys]: survey,
        [AfsVuexNamespaces.User]: userInfo,
        [AfsVuexNamespaces.Snackstand]: snackstand,
        [AfsVuexNamespaces.General]: general,
        [AfsVuexNamespaces.Preferences]: preferences
    },
})
