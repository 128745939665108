<template>
    <v-dialog v-model="localShow" @click:outside="goBackBtnClick">
        <v-card>
            <v-card-title>Review Responses</v-card-title>
            <v-img  class="right-free" width="500" :src="imageSrc" />
            <v-card-text>
                <v-row v-if="isRegistration">
                    <v-col>
                        Please keep the following in mind during registration:
                        <ul>
                            <li>You will be prompted for a verification code twice after clicking "Submit"</li>
                            <li>The verification codes will be texted to the cell phone number you entered in the registration form</li>
                            <li>You will receive two different codes- please wait to enter the second verification code
                                until a new one is received (i.e., you should be entering a different code in each prompt)</li>
                            <li>Failure to enter valid codes (e.g., closing out without entering, typos in entering) may
                                preclude you from registering with the desired email address without assistance from the study team</li>
                            <li>Please contact <a :href="`mailto:${studyEmail}`">{{studyEmail}}</a> if an issue occurs during registration</li>
                        </ul>
                    </v-col>
                </v-row>
                <v-row v-for="question in responses" :key="question.QuestionId">
                    <v-col cols="12" class="afs-markdown-gen pb-0 mb-1" v-html="format(question.Question)">
                    </v-col>
                        <v-chip color="primary" class="mb-2 mr-2" v-for="answer in question.Answer" :key="question.QuestionId + answer.AnswerId">
                            <span class="afs-markdown-gen text-wrap align-center mt-1 mx-1 rev-gen" v-html="format(answer.AnswerText)" />
                        </v-chip>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-btn color="secondary" @click="localShow = !localShow">Go Back</v-btn>
                <v-btn color="primary" class="ml-auto" text @click="submitBtnClick">Submit</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import {mapActions, mapGetters, mapState} from 'vuex'
import {AfsVuexNamespaces, PacketAssignment, Survey, UserInfo} from '@/constants/state'
import AfsParsedElement from '@/mixins/afsParsedElement'
// import { text } from 'body-parser'
// import {afsApi} from '@/utilities/api'

export default {
    name: 'AfsReview',
    mixins: [AfsParsedElement],
    props: {
        value: Boolean,
        imageSrc: {
            type: String,
            default: '',
        }
    },
    data: ()=>({
        submitPressed: false
    }),
    computed:{
        // ...mapGetters(AfsVuexNamespaces.Results.mapKey, [Result.SUBMISSION_RESULTS.getter]),
        ...mapState(AfsVuexNamespaces.Surveys.mapKey, [Survey.SURVEY_ID, Survey.SHOW_REVIEW]),
        ...mapGetters(AfsVuexNamespaces.Surveys.mapKey, {
            [Survey.RESPONSES]: Survey.RESPONSES.getter,
            [Survey.IS_REGISTRATION]: Survey.IS_REGISTRATION.getter,
        }),
        ...mapState(AfsVuexNamespaces.Assignments.mapKey, [PacketAssignment.CURRENT]),
        localShow: {
            get: function(){
                return this[Survey.SHOW_REVIEW]
            },
            set: function(newValue){
                this.$emit('input', this.submitPressed)
                this[Survey.SHOW_REVIEW.updater](newValue)
                return newValue
            }
        },
        responses: function() {
            return this[Survey.RESPONSES]
        },
        isRegistration: function(){
            return this[Survey.IS_REGISTRATION]
        },
        studyEmail: function (){
            return process.env.VUE_APP_AFS_ENGINE_STUDY_EMAIL
        }
    },
    methods: {
        ...mapActions(AfsVuexNamespaces.Surveys.mapKey, [Survey.SHOW_REVIEW.updater]),
        ...mapActions(AfsVuexNamespaces.User.mapKey, [UserInfo.SIGNUP.setter]),
        submitBtnClick: function(){
            this.submitPressed = true
            this.localShow = !this.localShow
            // if(this.isRegistration){
            //     this[UserInfo.SIGNUP.setter](true)
            // }
            // else {
            //     this.submitPressed = true
            //     this.localShow = !this.localShow
            // }
        },
        goBackBtnClick: function() {
            this.submitPressed = false
            this.localShow = !this.localShow
        },
    },
    watch: {
        localShow: function(val, oVal) {
            console.log(`watch localShow: ${val}`)
            if(!val && val != oVal)
                this.$emit('input', this.submitPressed)
        },
    }
}
</script>

<style scoped>
.v-chip {
    height: auto;
}
.v-image {
    text-align: center;
    align-content: center !important;
    float: right !important;
    right: 20px;
    top: 20px;
}

/* strip mailto formatting */
.v-chip >>> a[href^="mailto:"] {
    color: unset;
    text-decoration: unset;
}
</style>