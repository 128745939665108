<template>
    <v-radio-group v-model="localValue" :name="`${surveyId}.${itemId}`">
        <v-radio v-for="ansItem in answerItems" :value="ansItem.ItemID" :disabled="!enabled" :key="`afsAnsRadio.${ansItem.ItemID}`" :name="`${surveyId}.${itemId}.${ansItem.ItemID}`">
            <template v-slot:label>
                <div class="afs-markdown-gen" v-html="format(ansItem.EntryContent)"></div>
            </template>
        </v-radio>
    </v-radio-group>
</template>

<script>
import AfsSurveyItem from '@/mixins/afsSurveyItem'

export default {
    name: 'AfsAnswerRadio',
    mixins: [AfsSurveyItem],
    props: {
        value: {
            type: String,
            required: true,
        }
    },
    computed: {
        answerItems: function () {
            return this.entry?.Answers
        },
        localValue: {
            get() {
                if(this.response && this.response.length > 0)
                    return this.response[0].AnswerId
                return ''
            },
            set(value) {
                this.$emit('input', value)
            }
        }
    },
    methods: {
    },
    watch:{
        response: {
            handler: function(newVal){
                if(newVal.length < 1)
                    this.localValue = ''
                if(this.localValue === newVal[0].AnswerId)
                    return
                this.localValue = newVal[0].AnswerId
            }
        }
    }
}
</script>

<style scoped></style>
