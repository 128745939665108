import axios from 'axios'
import store from '../store'
import {AfsVuexNamespaces, Snackstand, Survey, UserInfo} from '@/constants/state'
import {SnackbarMessage, SnackbarStatuses} from '@/classes/SnackbarMessage'
import {PacketFileTypes} from '@/constants/PacketFileTypes'
import {joinIfValid} from '@/utilities/utils'
import router from '@/router'

class Endpoints {
    #prefix = ''

    constructor(prefix = '') {
        this.#prefix = prefix ? `${prefix}/`.replace(/\/+/, '/') : ''
    }

    get ASSIGNMENTS(){
        return `${this.#prefix}assignments`
    }

    get LOGIN() {
        return `${this.#prefix}login`
    }

    get USERS() {
        return `${this.#prefix}users`
    }

    get QUESTIONNAIRES() {
        return `${this.#prefix}questionnaires`
    }

    get SURVEYS() {
        return `${this.#prefix}surveys`
    }

    get STATUS() {
        return `${this.#prefix}status`
    }

    get RESULTS() {
        return `${this.#prefix}results`
    }

    get PACKETS() {
        return `${this.#prefix}packets`
    }

    get DATA_FILES() {
        return `${this.#prefix}data-files/`
    }

    get TRANSACTION() {
        return `${this.#prefix}transaction`
    }

    questionnaire(questionnaireId) {
        return [this.QUESTIONNAIRES, questionnaireId].join('/')
    }

    surveyStatus(surveyId) {
        return [this.SURVEYS, surveyId].join('/')
    }

    surveyProgress(surveyId) {
        return [this.SURVEYS, surveyId, 'progress']
    }
}

const endpoints = new Endpoints()
const openEndPoints = new Endpoints('open')

class AfsApi {
    constructor() {
        this._axios = axios.create({
            baseURL: process.env.VUE_APP_AFS_API_URL,
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Credentials': true,
            },
        })
        this._axios.interceptors.request.use(this.__interceptorRequest, this.__interceptorError)
        this._axios.interceptors.response.use(this.__interceptorResponse, this.__interceptorError)

        this._openAxios = axios.create({
            baseURL: process.env.VUE_APP_AFS_OPEN_API_URL,
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Credentials': true,
            },
        })
        this._openAxios.interceptors.request.use(this.__interceptorRequest, this.__interceptorError)
        this._openAxios.interceptors.response.use(this.__interceptorResponse, this.__interceptorError)
    }

    __interceptorRequest(config) {
        // console.log(`Interceptor Request config: ${JSON.stringify(config)}`)
        return config
    }

    __interceptorResponse(config) {
        // console.log(`Interceptor Response config: ${JSON.stringify(config)}`)
        return config
    }

    __interceptorError(error) {
        // console.log(`Interceptor Error config: ${error}`)
        // return error
        return Promise.reject(error)
    }

    async __postJson({handler = null, endpoint = '', data = null, params = null} = {}) {
        handler = handler || this._axios

        const idKey = `${AfsVuexNamespaces.User.mapKey}${UserInfo.ID_TOKEN.getter}`
        const idToken = store.getters[idKey]
        const locHeaders = {
            'Content-Type': 'application/json;charset=UTF-8',
        }
        if (idToken) locHeaders.Authorization = idToken

        // console.log(`headers: ${JSON.stringify(locHeaders)}`)
        const args = {
            method: 'POST',
            url: `${endpoint}`,
            contentType: 'application/json; charset=utf-8',
            processData: false,
            data: data,
        }
        if (locHeaders) args.headers = locHeaders
        if (params) args.params = params

        try {
            const resp = await handler(args)
            return resp
        }
        catch (err) {
            // Debugger seems incapable of finding 'err' :(
            const tErr = err
            console.warn(tErr)
            if(err.status == 401 || err.status == 402){
                await store.dispatch(`${AfsVuexNamespaces.Snackstand.mapKey}${Snackstand.MESSAGES.insertAction}`,
                    new SnackbarMessage({
                        state: SnackbarStatuses.ERROR,
                        message: 'Authentication error, redirecting to login page.'
                    })
                )
                await store.dispatch(`${AfsVuexNamespaces.User.mapKey}${UserInfo.LOGOUT}`)
            }
            throw err
        }
    }

    async __get({handler = null, endpoint = '', data = null, params} = {}) {
        handler = handler || this._axios
        const idKey = `${AfsVuexNamespaces.User.mapKey}${UserInfo.ID_TOKEN.getter}`
        const idToken = store.getters[idKey]

        const locHeaders = {}

        if (idToken)
            locHeaders.Authorization = idToken

        const args = {
            method: 'GET',
            url: `${endpoint}`,
            headers: locHeaders,
        }

        if (data && Object.keys(data).length > 0) args.data = data // JSON.stringify(data)
        if (params && Object.keys(params).length > 0) args.params = params

        try {
            const resp = await handler(args)
            // console.log(`got resp: ${resp}`)
            return resp
        }
        catch (err) {
            // Debugger seems incapable of finding 'err' :(
            const tErr = err
            console.warn(tErr)
            if(err.status == 401 || err.status == 402){
                await store.dispatch(`${AfsVuexNamespaces.Snackstand.mapKey}${Snackstand.MESSAGES.insertAction}`,
                    new SnackbarMessage({
                        state: SnackbarStatuses.ERROR,
                        message: 'Authentication error, redirecting to login page.'
                    })
                )
                await store.dispatch(`${AfsVuexNamespaces.User.mapKey}${UserInfo.LOGOUT}`)
            }
            throw err
        }
    }

    async getOpenSurvey(surveyId) {
        const resp = await this.__get({
            endpoint: openEndPoints.questionnaire(surveyId),
        })
        resp.requestedSurvey = surveyId
        resp.endpointCalled = endpoints.surveyProgress(surveyId)
        resp.endpointCalled = endpoints.surveyStatus(surveyId)
        return resp.data.survey
    }

    async getSurvey(surveyId) {
        const isReg = Survey.isRegistration(surveyId)
        const resp = await this.__get({
            endpoint: isReg ? openEndPoints.questionnaire(surveyId) : endpoints.questionnaire(surveyId),
        })
        resp.requestedSurvey = surveyId
        resp.endpointCalled = endpoints.surveyProgress(surveyId)
        resp.endpointCalled = endpoints.surveyStatus(surveyId)
        return resp.data.survey
    }

    async getQuestionnaires() {
        const resp = await this.__get({
            endpoint: endpoints.QUESTIONNAIRES
        })

        const retVal = resp?.data?.results

        return retVal
    }


    async getPacketAssignments(surveyId) {
        try {
            const resp = await this.__get({
                endpoint: joinIfValid({vals: [endpoints.ASSIGNMENTS, encodeURIComponent(surveyId)], joinStr: '/'}),
            })
            const retVal = resp?.data?.assignment || {}
            return retVal
        }
        catch {
            const msgKey = `${AfsVuexNamespaces.Snackstand.mapKey}${Snackstand.MESSAGES.insertAction}`
            store.dispatch(msgKey,
                new SnackbarMessage({
                    state: SnackbarStatuses.ERROR,
                    message: 'Unable to retrieve packet assignments.'
                })
            )
        }
    }

    async getResults({surveyId = '', in_progress = true} = '') {
        const msgKey = `${AfsVuexNamespaces.Snackstand.mapKey}${Snackstand.MESSAGES.insertAction}`
        if(!surveyId || surveyId.length < 1)
            return {}
        try {
            const resp = await this.__get({
                handler: this._axios,
                endpoint: joinIfValid({vals:[endpoints.QUESTIONNAIRES,  encodeURIComponent(surveyId), 'responses', in_progress? 'in-progress': ''], joinStr: '/'}),
            })
            // console.log(`results: ${resp?.data?.results}`)
            if(!resp?.data?.results)
                return {}
            return resp.data.results
        }
        catch (e) {
            console.warn(e)
            await store.dispatch(msgKey,
                new SnackbarMessage({
                    state: SnackbarStatuses.ERROR,
                    message: `Unable to retrieve partial ${surveyId} answers.`
                })
            )
            await router.push('/home')
        }
    }

    async submitResults({results = [], surveyId = '', packetId = '', in_progress = false} = {}) {
        const msgKey = `${AfsVuexNamespaces.Snackstand.mapKey}${Snackstand.MESSAGES.insertAction}`

        try {
            await this.__postJson({
                handler: this._axios,
                endpoint: joinIfValid({vals:[endpoints.QUESTIONNAIRES,  encodeURIComponent(surveyId)], joinStr: '/'}),
                data: {
                    questionnaire_id: surveyId,
                    survey_id: surveyId,
                    packetId: packetId,
                    results: results,
                    in_progress: in_progress
                }
            })
            let msg = ''
            if(in_progress)
                msg = 'Auto-save complete.'
            else if (packetId)
                msg = `Successfully submitted ${surveyId} answers for packet ${packetId}.`
            else
                msg = `Successfully submitted ${surveyId} answers.`
            await store.dispatch(msgKey,
                new SnackbarMessage({
                    state: SnackbarStatuses.SUCCESS,
                    message: msg
                })
            )
            return true
        }
        catch (e) {
            console.warn(e)

            let msg = ''
            if(in_progress)
                msg = 'Auto-save failed.'
            else
                msg = `Unable to submit ${surveyId} answers.`

            await store.dispatch(msgKey,
                new SnackbarMessage({
                    state: SnackbarStatuses.ERROR,
                    message: msg
                })
            )
            return false
        }
    }

    async getAccountInfo() {
        try {
            const resp = await this.__get({
                handler: this._axios,
                endpoint: 'account/user-info',
            })
            return {
                email: resp?.data?.user_email,
                participantId: resp?.data?.user_participant_id
            }
        }
        catch (e) {
            const msgKey = `${AfsVuexNamespaces.Snackstand.mapKey}${Snackstand.MESSAGES.insertAction}`
            await store.dispatch(msgKey,
                new SnackbarMessage({
                    state: SnackbarStatuses.ERROR,
                    message: 'Unable to retrieve account info.'
                })
            )
        }
    }

    async getPacketData(args = {}) {
        try {
            const resp = await this.__get({
                endpoint: '/packets/get-packet',
                params: args
            })
            return resp
        }
        catch {
            const msgKey = `${AfsVuexNamespaces.Snackstand.mapKey}${Snackstand.MESSAGES.insertAction}`
            await store.dispatch(msgKey,
                new SnackbarMessage({
                    state: SnackbarStatuses.ERROR,
                    message: 'Unable to retrieve packet info.'
                })
            )
        }
    }

    async getPacketDescription(questionnaireId = '', selector = '', assignmentId = '', packetId = '') {
        if(!packetId)
            return ''
        const resp = await this.getPacketData({
            questionnaire_id: questionnaireId,
            selector: selector,
            assignment_id: assignmentId,
            packet_id: packetId,
            type: PacketFileTypes.DESCRIPTION,
            psl: false
        })
        return resp?.data?.data?.Description
    }

    async getPacketProofSheet(questionnaireId = '', selector = '', assignmentId = '', packetId = '') {
        if(!packetId)
            return ''
        const resp = await this.getPacketData({
            questionnaire_id: questionnaireId,
            selector: selector,
            assignment_id: assignmentId,
            packet_id: packetId,
            type: PacketFileTypes.PROOF_SHEET,
            psl: true
        })
        return resp?.data?.url
    }

    async getLatentPacket(questionnaireId = '', fileType = '', assignmentId = '', packetId = '', mode = '') {
        if(!packetId)
            return ''
        const resp = await this.getPacketData({
            questionnaire_id: questionnaireId,
            selector: '',
            assignment_id: assignmentId,
            packet_id: packetId,
            type: fileType,
            mode: mode,
            psl: true
        })
        return resp?.data?.url
    }

    async getPacketDownload(questionnaireId = '', selector = '', assignmentId = '', packetId = '') {
        if(!packetId)
            return ''
        const resp = await this.getPacketData({
            questionnaire_id: questionnaireId,
            selector: selector,
            assignment_id: assignmentId,
            packet_id: packetId,
            type: PacketFileTypes.PACKET,
            psl: true
        })
        return resp?.data?.url
    }

    async getDataFiles(category=''){
        const idKey = `${AfsVuexNamespaces.User.mapKey}${UserInfo.ID_TOKEN.getter}`
        const idToken = store.getters[idKey]
        category = category ? `/${encodeURIComponent(category)}`: ''
        try {
            const resp = await this.__get({
                endpoint: `${idToken? endpoints.DATA_FILES: openEndPoints.DATA_FILES}${category}`,
            })
            return resp.data.documents
        }
        catch {
            const msgKey = `${AfsVuexNamespaces.Snackstand.mapKey}${Snackstand.MESSAGES.insertAction}`
            await store.dispatch(msgKey,
                new SnackbarMessage({
                    state: SnackbarStatuses.ERROR,
                    message: 'Unable to retrieve data files.'
                })
            )
        }
    }

    async saveTransactionLog(questionnaireId = '', assignmentId = '', packetId = '', logType = '', toLog=''){
        const msgKey = `${AfsVuexNamespaces.Snackstand.mapKey}${Snackstand.MESSAGES.insertAction}`

        try {
            await this.__postJson({
                handler: this._axios,
                endpoint: joinIfValid({vals:[endpoints.TRANSACTION,  encodeURIComponent(questionnaireId)], joinStr: '/'}),
                data: {
                    questionnaireId: questionnaireId,
                    surveyId: questionnaireId,
                    assignmentId: assignmentId,
                    packetId: packetId,
                    transactionType: logType,
                    logData: toLog,
                }
            })
            // let msg = ''
            // await store.dispatch(msgKey,
            //     new SnackbarMessage({
            //         state: SnackbarStatuses.SUCCESS,
            //         message: msg
            //     })
            // )
            return true
        }
        catch (e) {
            console.warn(e)

            await store.dispatch(msgKey,
                new SnackbarMessage({
                    state: SnackbarStatuses.ERROR,
                    message: 'Unable to send message to backend'
                })
            )
            return false
        }
    }
}

export const afsApi = new AfsApi()
