<template>
    <v-btn v-if="safeMode === 'menu' && !entry.subs" @click="$refs[`menu-${titleAsRef}}`].click()">
        {{title}}
        <a class="d-none" :href="entry.link" target="_blank" :ref="`menu-${titleAsRef}}`" />
    </v-btn>
    <v-menu v-else-if="safeMode === 'menu' && entry.subs" :close-on-content-click="false"
            offset-x>
        <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on">{{title}}</v-btn>
        </template>
        <v-list>
            <v-list-item v-for="item in entry.subs" :key="`menu-${item.title}}`">
                <template v-slot:default>
                    <afs-list :entry="item" :mode="mode" />
                </template>
            </v-list-item>
        </v-list>
    </v-menu>
    <li v-else-if="safeMode === 'list'">
        <template  v-if="entry.subs">
            {{title}}
            <ul>
                <afs-list v-for="item in entry.subs" :key="`list-${item.title}`" :entry="item" :mode="mode" />
            </ul>
        </template>
        <a v-else :href="entry.link" target="_blank" :ref="`list-${titleAsRef}`" >{{title}}</a>
    </li>
</template>

<script>
export default {
    name: 'AfsList',
    props: {
        entry: {
            type: Object,
            required: true,
        },
        mode: {
            type: String,
            defaultValue: 'menu',
        },
    },
    computed: {
        title: function(){
            return this.entry.title
        },
        safeMode: function(){
            return (this.mode || 'menu').trim().toLowerCase()
        },
        titleAsRef: function(){
            return this.title.replace(/\s+/, '-').replace(/-+/, '-')
        }
    }
}
</script>

<style scoped>

</style>