import {AfsVuexNamespaces, General, PreferencesKeys, QuestionnaireElementKeys, Status, Survey} from '@/constants/state'
import {CaseInsensitiveTranslator, joinIfValid} from '@/utilities/utils'
import {AfsEngineConditionalOn} from '@/utilities/AfsEngineConditionalOn'
import {REFERENCE_REGEX_GLOBAL} from '@/constants/AfsEngineConditionalOperations'
import * as marked from 'marked'
import DOMPurify from 'dompurify'
import {RefToModule} from '@/classes/ElementReferenceArgs'
import {ItemTypes} from '@/constants/AfsEngineTypes'
import {AfsEngineQuestionValidators} from '@/classes/afsQuestionValidator'
import {AfsEngineSurveyNavigationMode} from '@/constants/AfsEngineSurveyNavigationMode'



const state = () => {
    return {
        entry: {},
        trans: {},
        conditionalOn: {},
        [QuestionnaireElementKeys.REFERENCES.toString()]: [],
        [QuestionnaireElementKeys.RESPONSE.toString()]: [],
        [QuestionnaireElementKeys.ELEMENT_NAMESPACE]: ''
    }
}

const defaultOptions = state()

const deliberateGetters = {
    [QuestionnaireElementKeys.CONDITIONAL_ON.getter]: (state) => { //, getters, rootState) => {
        return state.conditionalOn
    },
    [QuestionnaireElementKeys.CONDITIONS_MET.getter]: (state, getters, rootState) => {
        const condOn = getters[QuestionnaireElementKeys.CONDITIONAL_ON.getter]
        if (!condOn || !condOn.result)
            return true
        const retVal = condOn.result({
            items: rootState[AfsVuexNamespaces.Surveys][Survey.ENTRIES],
            results: getters[QuestionnaireElementKeys.REFERENCES_RESPONSES.getter]
        })
        return retVal
    },
    [QuestionnaireElementKeys.REFERENCES.getter]: (state, _, rootState) => {
        const conditionalText = state.trans[QuestionnaireElementKeys.CONDITIONAL_ON.sanitized] || ''
        const entryContent = state.trans[QuestionnaireElementKeys.ENTRY_CONTENT.sanitized] || ''
        const parentEntry = state.trans[QuestionnaireElementKeys.PARENT_ID.sanitized] ?
            rootState[AfsVuexNamespaces.Surveys][Survey.ENTRIES.toString()][state.trans[QuestionnaireElementKeys.PARENT_ID.sanitized]] :
            null

        const parentRefArr = parentEntry ? [joinIfValid({vals: ['current', parentEntry.ParentID, parentEntry.ItemID]})] : []
        let retVal = [
            ...Array.from(conditionalText.matchAll(REFERENCE_REGEX_GLOBAL)).map(x => x[1]),
            ...Array.from(entryContent.matchAll(REFERENCE_REGEX_GLOBAL)).map(x => x[1]),
            ...parentRefArr
        ].reduce((acc, ele) => {
            acc.add(ele)
            return acc
        }, new Set())
        retVal = Array.from(retVal)
        return retVal
    },
    [QuestionnaireElementKeys.REFERENCES_ENABLED_STATES.getter]: (state, getters, rootState, rootGetters) => {
        if (getters[QuestionnaireElementKeys.REFERENCES.getter].length < 1)
            return true
        const retVal = getters[QuestionnaireElementKeys.REFERENCES.getter]
            .map(x => {
                const refId = rootGetters[`${AfsVuexNamespaces.General.mapKey}${General.REF_AS_MODULE.getter}`](x)
                if (!(refId in rootState))
                    return true
                const refEnabled = rootGetters[`${refId}/${QuestionnaireElementKeys.ENABLED.getter}`]
                return refEnabled
            })
        return retVal
    },
    [QuestionnaireElementKeys.REFERENCES_ENABLED.getter]: (state, getters) => { //, __, rootGetters)=>{
        if (getters[QuestionnaireElementKeys.REFERENCES.getter].length < 1)
            return true
        const retVal = getters[QuestionnaireElementKeys.REFERENCES_ENABLED_STATES.getter]
            .every(x => x)
        return retVal
    },
    [QuestionnaireElementKeys.REFERENCES_RESPONSES.getter]: (state, getters, rootState, rootGetters) => {
        if (getters[QuestionnaireElementKeys.REFERENCES.getter].length < 1)
            return {}
        const retVal = getters[QuestionnaireElementKeys.REFERENCES.getter]
            .reduce((acc, x) => {
                const refId = rootGetters[`${AfsVuexNamespaces.General.mapKey}${General.REF_AS_MODULE.getter}`](x)
                if (!(refId in rootState))
                    return acc
                const refResponse = rootState[refId][QuestionnaireElementKeys.RESPONSE]
                if (!refResponse)
                    return acc
                acc[refId] = refResponse
                return acc
            }, {})
        return retVal
    },
    [QuestionnaireElementKeys.ENABLED.getter]: (state, getters) => {//, rootState, rootGetters) => {
        if (!getters[QuestionnaireElementKeys.REFERENCES_ENABLED.getter]) return false
        const retVal = getters[QuestionnaireElementKeys.CONDITIONS_MET.getter]

        return retVal
    },
    [QuestionnaireElementKeys.PARSED_CONTENT.getter]: (state) => {
        let html = marked.parse(state[QuestionnaireElementKeys.ENTRY_CONTENT.getter] || '')
        const clean = DOMPurify.sanitize(html)
        return clean
    },
    [QuestionnaireElementKeys.CHILDREN.getter]: (_, getters, __, rootGetters) => {
        const retVal = rootGetters[`${AfsVuexNamespaces.Surveys.mapKey}${Survey.ELEMENTS_BY_PARENT.getter}`](getters[QuestionnaireElementKeys.ITEM_ID.getter])
            .map(x => x.ItemID)
        return retVal || []
    },
    [QuestionnaireElementKeys.CHILDREN_RESPONSES.getter]: (state, getters, rootState, rootGetters) => {
        if (getters[QuestionnaireElementKeys.CHILDREN.getter].length < 1)
            return []
        const retVal = getters[QuestionnaireElementKeys.CHILDREN.getter]
            .reduce((acc, x) => {
                const refId = rootGetters[`${AfsVuexNamespaces.General.mapKey}${General.REF_AS_MODULE.getter}`](`CURRENT.${x}`)
                if (!(refId in rootState))
                    return acc
                const refResponse = rootState[refId][QuestionnaireElementKeys.RESPONSE]
                if (!refResponse)
                    return acc
                acc[refId] = refResponse
                return acc
            }, {})
        return retVal
    },
    [QuestionnaireElementKeys.VALID.getter]: (state, getters) => {
        if (!getters[QuestionnaireElementKeys.ENABLED.getter])
            return true
        const childrenValid = getters[QuestionnaireElementKeys.CHILDREN_VALID.getter]
        if (childrenValid.some(x => x === false))
            return false

        if (getters[QuestionnaireElementKeys.ITEM_TYPE.getter].trim().toLowerCase() === ItemTypes.QUESTION.toLowerCase()) {
            const responses = state[QuestionnaireElementKeys.RESPONSE] || []
            const hasResponses = responses.length > 0 && responses.every(x => (x?.AnswerText || '').length > 0)
            const questionType = getters[QuestionnaireElementKeys.QUESTION_TYPE.getter] || ''

            // Intentiaonal "short circuit" for questions with no type
            if (!questionType) return true

            if (getters[QuestionnaireElementKeys.IS_REQUIRED.getter] && !hasResponses)
                return false
            const validator = AfsEngineQuestionValidators[questionType.trim().toLowerCase()]
            if (validator) {
                const validated = state[QuestionnaireElementKeys.RESPONSE].every(ele => {
                    return validator.validation(ele.AnswerText)
                })
                return validated
            }
            return hasResponses
        }
        return childrenValid
    },
    [QuestionnaireElementKeys.CHILDREN_VALID.getter]: (_, getters, rootState, rootGetters) => {
        const children = getters[QuestionnaireElementKeys.CHILDREN.getter]
        const retVal = children.reduce((acc, x) => {
            const childId = rootGetters[`${AfsVuexNamespaces.General.mapKey}${General.REF_AS_MODULE.getter}`](
                new RefToModule({
                    questionnaireId: getters[QuestionnaireElementKeys.SURVEY_ID.getter],
                    itemId: x
                })
            )
            if (!(childId in rootState))
                return acc
            acc.push(rootGetters[`${childId}/${QuestionnaireElementKeys.VALID.getter}`])
            return acc
        }, [])
        return (retVal && retVal.length > 0) ? retVal : [true]
    },
    [QuestionnaireElementKeys.COMPLETE.getter]: (state, getters) => {
        if (!getters[QuestionnaireElementKeys.ENABLED.getter])
            return true
        if (getters[QuestionnaireElementKeys.CHILDREN_COMPLETE.getter].some(x => !x))
            return false
        if (!getters[QuestionnaireElementKeys.VALID.getter])
            return false
        const responses = state[QuestionnaireElementKeys.RESPONSE]
        if (getters[QuestionnaireElementKeys.IS_REQUIRED.getter] && (responses?.length || 0) < 1)
            return false
        return true
    },
    [QuestionnaireElementKeys.CHILDREN_COMPLETE.getter]: (_, getters, rootState, rootGetters) => {
        const children = getters[QuestionnaireElementKeys.CHILDREN.getter]
        const retVal = children.reduce((acc, x) => {
            const childId = rootGetters[`${AfsVuexNamespaces.General.mapKey}${General.REF_AS_MODULE.getter}`](
                new RefToModule({
                    questionnaireId: getters[QuestionnaireElementKeys.SURVEY_ID.getter],
                    itemId: x
                })
            )
            if (!(childId in rootState))
                return acc
            acc.push(rootGetters[`${childId}/${QuestionnaireElementKeys.COMPLETE.getter}`])
            return acc
        }, [])
        return (retVal && retVal.length > 0) ? retVal : [true]
    },
    [QuestionnaireElementKeys.ALWAYS_EXPANDED.getter]: (_, getters, rootState) => {
        const itemType = getters[QuestionnaireElementKeys.ITEM_TYPE.getter].toLowerCase()
        if (!getters[QuestionnaireElementKeys.ENABLED.getter])
            return false
        // Adding/removing the enabled check controls if this element being enabled overrides its desire to be expanded
        if (ItemTypes.ALWAYS_EXPANDED.has(itemType))
            return true
        const navMode = rootState[AfsVuexNamespaces.Surveys][Survey.NAVIGATION_MODE].toLowerCase()
        if (navMode === AfsEngineSurveyNavigationMode.CAROUSEL.toLowerCase() &&
            !getters[QuestionnaireElementKeys.PARENT_ID.getter] &&
            itemType == ItemTypes.SECTION.toLowerCase()
        )
            return true
        if(itemType === ItemTypes.REQUIRED_SECTION.toLowerCase())
            return true
        if(rootState[AfsVuexNamespaces.Preferences][PreferencesKeys.EXPANDED_BY_DEFAULT] && ItemTypes.isExpansion(itemType))
            return true
        return false
    },
    [QuestionnaireElementKeys.CHILDREN_ALWAYS_EXPANDED.getter]: (_, getters, rootState, rootGetters) => {
        if (getters[QuestionnaireElementKeys.CHILDREN.getter].length < 1)
            return []
        const retVal = getters[QuestionnaireElementKeys.CHILDREN.getter]
            .reduce((acc, x) => {
                const refId = rootGetters[`${AfsVuexNamespaces.General.mapKey}${General.REF_AS_MODULE.getter}`](`CURRENT.${x}`)
                if (!(refId in rootState))
                    return acc

                const expanded = rootGetters[`${refId}/${QuestionnaireElementKeys.ALWAYS_EXPANDED.getter}`]
                if(expanded) acc.push(x)
                return acc
            }, [])
        return retVal
    },
}

const getters = {
    ...deliberateGetters,
    ...Object.values(QuestionnaireElementKeys).reduce((acc, ele) => {
        if(!Object.hasOwnProperty.call(deliberateGetters, ele.getter)){
            acc[ele.getter] = (state) => {
                if (ele.toString() in state)
                    return state[ele.toString()]
                return state.trans[ele.sanitized] || ''
            }
        }
        return acc
    }, {})
}

const mutations = {
    [Status.INITIALIZED.setter]: function(state, payload){
        // TODO: FOR NOW convert to a "real" objet
        const locPayload = (typeof payload === 'string' || payload instanceof String)? JSON.parse(payload): {...payload}
        // locPayload = {...locPayload}
        state.entry = locPayload
        state.trans = new CaseInsensitiveTranslator(payload)
        const conditionalOn = state.trans[QuestionnaireElementKeys.CONDITIONAL_ON]
        const surveyId = state.trans[QuestionnaireElementKeys.SURVEY_ID]
        state.conditionalOn = AfsEngineConditionalOn.fromParsed({
            currentSurveyId: surveyId,
            currentPacketId: '',
            ...JSON.parse(conditionalOn)
        })

        state[QuestionnaireElementKeys.ELEMENT_NAMESPACE] = payload.elementNamespace || joinIfValid({vals: [payload.surveyId, payload.itemId]})
    },
    [Status.CLEAR.setter]: function(state){
        const ok = !!defaultOptions || true
        if(ok)
            state[QuestionnaireElementKeys.RESPONSE] = []
    },
    [QuestionnaireElementKeys.RESPONSE.setter]: (state, payload) => {
        state[QuestionnaireElementKeys.RESPONSE] = payload
    },

    ...Object.values(QuestionnaireElementKeys).reduce((acc, ele) => {
        if(!Object.hasOwnProperty.call(deliberateGetters, ele.getter)){
            acc[ele.setter] = (state, payload) => {
                state[ele.sanitized] = payload
            }
        }
        return acc
    }, {})
}

const actions = {
    [Status.INITIALIZED.updater]: ({commit, dispatch, rootGetters}, payload) => {
        const elementNamespace = rootGetters[`${AfsVuexNamespaces.General.mapKey}${General.REF_AS_MODULE.getter}`](
            new RefToModule({
                questionnaireId: payload.SurveyID,
                itemId: payload.ItemID,
            }))
        commit(Status.INITIALIZED.setter, {elementNamespace: elementNamespace, ...payload})
        // const resId = joinIfValid({vals: [payload.SurveyID, payload.ItemID], joinStr: '-'})
        dispatch(`${AfsVuexNamespaces.General.mapKey}${General.AFS_ELEMENT_MODULES.updater}`, elementNamespace, {root:true})
    },
    [QuestionnaireElementKeys.RESPONSE.updater]: ({commit}, payload) => {
        commit(QuestionnaireElementKeys.RESPONSE.setter, payload)
    },
    [Status.CLEAR.updater]({commit}, payload){
        commit(Status.CLEAR.setter, payload)
    },

    ...Object.values(QuestionnaireElementKeys).reduce((acc, ele) => {
        if(!Object.hasOwnProperty.call(deliberateGetters, ele.getter)){
            acc[ele.updater] = ({commit}, payload) => {
                commit(ele.setter, payload)
            }
        }
        return acc
    }, {})
}

export default {
    modules: {},
    namespaced: true,
    state: state,
    getters: getters,
    mutations: mutations,
    actions: actions
}
