import {AfsVuexNamespaces, PacketAssignment, Status, Survey} from '@/constants/state'
import {CaseInsensitiveTranslator} from '@/utilities/utils'
import { afsApi } from '@/utilities/api'

let state = () => {
    return {
        [PacketAssignment.CURRENT_INDEX]: -1,
        [PacketAssignment.PACKETS]: [],
        [PacketAssignment.ASSIGNMENT_ID]: '',
        [Status.INITIALIZED]: false,
    }
}

const defaultOptions = state()

let mutations = {
    [Status.SET.setter](state, payload) {
        let trans = new CaseInsensitiveTranslator(payload)
        if (trans.assignment) trans = CaseInsensitiveTranslator(trans.assignment)
        if (!trans[PacketAssignment.SURVEY_ID.sanitized]) return
        state[PacketAssignment.CURRENT_INDEX] = Math.max(
            trans[PacketAssignment.CURRENT_INDEX.sanitized] || 0,
            trans['LastAnsweredIndex'] || 0,
            0
        )
        state[PacketAssignment.ASSIGNMENT_ID] =
            trans[PacketAssignment.ASSIGNMENT_ID.sanitized]
        state[PacketAssignment.PACKETS] = trans[PacketAssignment.PACKETS.sanitized]
        state[Status.INITIALIZED] = true
    },

    [PacketAssignment.CURRENT_INDEX.setter](state, payload) {
        if (payload >= 0 && payload < state[PacketAssignment.PACKETS].length)
            state[PacketAssignment.CURRENT_INDEX] = payload
    },
    [Status.CLEAR.setter]: function(state){
        for (const [key, value] of Object.entries(defaultOptions))
            state[key] = value
    },
}

let getters = {
    [PacketAssignment.CURRENT.getter](state) {
        if (
            state[PacketAssignment.PACKETS] &&
            state[PacketAssignment.CURRENT_INDEX] >= 0
        )
            return state[PacketAssignment.PACKETS][state[PacketAssignment.CURRENT_INDEX]]

        return {}
    },
    [PacketAssignment.NUM_PACKETS.getter](state){
        return state[PacketAssignment.PACKETS]?.length || 0
    },
    [PacketAssignment.COMPLETE.getter](state, getters) {
        if (
            state[PacketAssignment.PACKETS] &&
            state[PacketAssignment.CURRENT_INDEX] >= 0
        )
            return state[PacketAssignment.CURRENT_INDEX] + 1 >= getters[PacketAssignment.NUM_PACKETS.getter]

        return true
    },
}

let actions = {
    [AfsVuexNamespaces.Assignments.initializer]({commit, rootState}) {

        return afsApi.getPacketAssignments(rootState[AfsVuexNamespaces.Surveys][Survey.SURVEY_ID])
            .then((resp) => {
                commit(Status.SET.setter, resp)
            })
    },
    [PacketAssignment.NEXT.updater]({commit, state}) {
        commit(
            PacketAssignment.CURRENT_INDEX.setter,
            state[PacketAssignment.CURRENT_INDEX] + 1
        )
    },
    [Status.CLEAR.updater]({commit}, payload){
        commit(Status.CLEAR.setter, payload)
    },
}

export default {
    modules: {},
    namespaced: true,
    state: state,
    getters: getters,
    mutations: mutations,
    actions: actions,
}
