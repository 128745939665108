export class StateString extends String {
    #prefix = ''
    #base
    constructor(value='', prefix='') {
        super(`${prefix}${value}`)
        this.#base = value
        this.#prefix = prefix
    }
    get getter() {
        return `get${this}`
    }
    get setter() {
        return `set${this}`
    }
    get updater() {
        return `update${this}`
    }
    get initializer() {
        return `init${this}`
    }
    get mapKey() {
        return `${this}/`
    }
    get toggle(){
        return `toggle${this}`
    }
    get sanitized(){
        return this.#base
    }
}

export class StateArray extends StateString {
    get insertMutation() {
        return `insert${this}Mutate`
    }
    get insertAction() {
        return `insert${this}Action`
    }
    get removeMutation() {
        return `remove${this}Mutate`
    }
    get removeAction() {
        return `remove${this}Action`
    }
}

export class Status {
    static get INITIALIZED() {
        return new StateString('Initialized')
    }

    static get SET() {
        return new StateString('Set')
    }

    static get CLEAR() {
        return new StateString('Clear')
    }

    static get SUCCESS(){
        return new StateString('Success')
    }

    static get ERROR(){
        return new StateString('Error')
    }
}

export class LoginState extends Status{
    static get NONE(){
        return new StateString('None')
    }

    static get ACCOUNT_CREATED(){
        return new StateString('AccountCreate')
    }

    static get VERIFIED() {
        return new StateString('Verified')
    }
}

export class Navigational {
    static get PREV() {
        return new StateString('Previous')
    }

    static get NEXT() {
        return new StateString('Next')
    }
}

export let AfsVuexNamespaces = Object.freeze({
    Assignments: new StateString('Assignments'),
    Surveys: new StateString('Surveys'),
    User: new StateString('User'),
    Snackstand: new StateString('Snackstand'),
    General: new StateString('General'),
    Preferences: new StateString('Preferences')
})

export class Packet {
    static #prefix = 'Pkt'
    static get PACKET_ID() {
        return new StateString(`${this.#prefix}PacketId`)
    }

    static get PACKET_KEY() {
        return new StateString(`${this.#prefix}PacketKey`)
    }
}

const packetAssignmentPrefix = 'PktAsgn'
export const PacketAssignment = Object.freeze({
    ASSIGNMENT_ID: new StateString('AssignmentId', packetAssignmentPrefix),
    LAST_ANSWERED_INDEX: new StateString('LastAnsweredIndex', packetAssignmentPrefix),
    ORDER: new StateString('Order', packetAssignmentPrefix),
    PACKETS: new StateString('Packets', packetAssignmentPrefix),
    NUM_PACKETS: new StateString('NumPackets', packetAssignmentPrefix),
    SELECTOR: new StateString('Selector', packetAssignmentPrefix),
    SURVEY_ID: new StateString('SurveyId', packetAssignmentPrefix),
    CURRENT_INDEX: new StateString('CurrentIdx', packetAssignmentPrefix),
    CURRENT: new StateString('Current', packetAssignmentPrefix),
    NEXT: new StateString('Next', packetAssignmentPrefix),
    COMPLETE: new StateString('Complete', packetAssignmentPrefix),
})

const surveyPrefix = 'Srvy'
export const Survey = Object.freeze({
    prefix: surveyPrefix,
    SURVEY_ID: new StateString('SurveyId', surveyPrefix),
    NAVIGATION_MODE: new StateString('NavigationMode', surveyPrefix),
    SURVEY_TITLE: new StateString('Title', surveyPrefix),
    ENTRIES: new StateString('Entries', surveyPrefix),
    REFERENCES: new StateString('References', surveyPrefix),
    SPEC_VERSION: new StateString('SpecVersion', surveyPrefix),
    SURVEY_VERSION: new StateString('SurveyVersion', surveyPrefix),
    SELECTOR: new StateString('Selector', surveyPrefix),
    ENTRY_ORDER_MAP: new StateString('EntryOrderMap', surveyPrefix),
    ELEMENTS_BY_PARENT: new StateString('ElementsByParent', surveyPrefix),
    SURVEY_ITEM_IDS: new StateString('SurveyItemIds', surveyPrefix),
    REFERENCED_IDS: new StateString('ReferenceIds', surveyPrefix),
    CURRENT: 'CURRENT',
    REGISTRATION: new StateString('Registration', surveyPrefix),
    IS_REGISTRATION: new StateString('IsRegistration', surveyPrefix),
    REGISTRATION_KEYS: new StateString('RegistrationKeys', surveyPrefix),
    SHOW_REVIEW: new StateString('ShowReview', surveyPrefix),
    COMPLETE: new StateString('Complete', surveyPrefix),
    TOPS_COMPLETE: new StateString('TopsComplete', surveyPrefix),
    RESPONSES: new StateString('Responses', surveyPrefix),
    IS_TERMINATING: new StateString('IsTerminating', surveyPrefix),
    TOPS_ALWAYS_EXPANDED: new StateString('TopsAlwaysExpanded', surveyPrefix),
    ALWAYS_SHOW_VALIDITY: new StateString('AlwaysShowValidity', surveyPrefix),
    PACKET_STRING: new StateString('PacketString', surveyPrefix),

    isCurrent: function (surveyId) {
        const regex = new RegExp(`^${Survey.CURRENT}$`, 'i')
        return surveyId.trim().toLowerCase().match(regex) ? true : false
    },
    isRegistration: function (surveyId){
        const regex = new RegExp('^(reg|registration|signup)$', 'i')
        return surveyId.trim().toLowerCase().match(regex)    ? true: false
    }
})

export class ResultStatusTrackers{
    static get ENABLED(){
        return 'enabled'
    }

    static get VALID(){
        return 'valid'
    }
}

const userInfoPrefix = 'UsrInfo'
export const UserInfo = Object.freeze({
    'NAME': new StateString('Name', userInfoPrefix),
    'EMAIL': new StateString('Email', userInfoPrefix),
    'PASSWORD': new StateString('Password', userInfoPrefix),
    'ENTER_OTP': new StateString('EnterOtp', userInfoPrefix),
    'SIGNUP': new StateString('Signup', userInfoPrefix),
    'LOGIN_STATE': new StateString('LoginState', userInfoPrefix),
    'LOGOUT': new StateString('Logout', userInfoPrefix),
    'LOGGED_IN': new StateString('LoggedIn', userInfoPrefix),
    'COGNITO_USER': new StateString('CognitoUser', userInfoPrefix),
    'SIGNUP_COMPLETE': new StateString('SignupComplete', userInfoPrefix),
    'SESSION': new StateString('Session', userInfoPrefix),
    'ID_TOKEN': new StateString('IdToken', userInfoPrefix),
    'ACCOUNT_INFO': new StateString('AccountInfo', userInfoPrefix),
    'PARTICIPANT_ID': new StateString('ParticipantId', userInfoPrefix),
    'LOGIN_FAILED': new StateString('LoginFailed', userInfoPrefix),
    'MAX_MFA_ATTEMPTS': new StateString('MaxMfaAttmpts', userInfoPrefix),
    'MFA_ATTEMPTS': new StateString('MfaAttempts', userInfoPrefix),
})

const SnackstandPrefix = 'AfsSnk'
export const Snackstand = Object.freeze({
    'MESSAGES': new StateArray('Messages', SnackstandPrefix),
    'WINDOW': new StateArray('Window', SnackstandPrefix),
    'PAGE_SIZE': new StateArray('PageSize', SnackstandPrefix),
})

const GeneralPrefix = 'AfsGeneral'
export const General = Object.freeze({
    'NAV_BAR': new StateString('NavBar', GeneralPrefix),
    'DATA_FILES': new StateString('Documents', GeneralPrefix),
    'REF_AS_MODULE': new StateString('RefAsModule', GeneralPrefix),
    'AFS_ELEMENT_MODULES': new StateString('ElementModules', GeneralPrefix)
})

export const QuestionnaireElementKeys = Object.freeze({
    'CONDITIONAL_ON': new StateString('ConditionalOn'),
    'ENTRY_CONTENT': new StateString('EntryContent'),
    'ID': new StateString('Id'),
    'IS_REQUIRED': new StateString('IsRequired'),
    'IS_TERMINATING': new StateString('IsTerminating'),
    'ITEM_ID': new StateString('ItemId'),
    'ITEM_TYPE': new StateString('ItemType'),
    'PARENT_ID': new StateString('ParentId'),
    'QUESTION_TYPE': new StateString('QuestionType'),
    'ROW_ID': new StateString('RowId'),
    'SURVEY_ID': new StateString('SurveyId'),
    'SURVEY_KEY': new StateString('SurveyKey'),
    // ^^^ Column names from DB
    // vvv Runtime info (add to "skipDefaultGetter")
    'REFERENCES': new StateString('References'),
    'REFERENCES_ENABLED': new StateString('ReferencesEnabled'),
    'REFERENCES_ENABLED_STATES': new StateString('ReferencesEnabledStates'),
    'REFERENCES_RESPONSES': new StateString('ReferencesResponses'),
    'ENABLED': new StateString('Enabled'),
    'VALID': new StateString('Valid'),
    'RESPONSE': new StateString('Response'),
    'PARSED_CONTENT': new StateString('ParsedContent'),
    'CONDITIONS_MET': new StateString('ConditionsMet'),
    'ELEMENT_NAMESPACE': new StateString('ElementNamespace'),
    'CHILDREN': new StateString('Children'),
    'CHILDREN_RESPONSES': new StateString('ChildrenResponses'),
    'CHILDREN_VALID': new StateString('ChildrenValid'),
    'COMPLETE': new StateString('Complete'),
    'CHILDREN_COMPLETE': new StateString('ChildrenComplete'),
    'ALWAYS_EXPANDED': new StateString('AlwaysExpanded'),
    'CHILDREN_ALWAYS_EXPANDED': new StateString('ChildrenAlwaysExpanded'),
})

export const PreferencesKeys = Object.freeze({
    EXPANDED_BY_DEFAULT: new StateString('ExpandedByDefault')
})
