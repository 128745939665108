import afsParsedElement from '@/mixins/afsParsedElement'
import {mapGetters, mapState} from 'vuex'
import {
    AfsVuexNamespaces,
    General,
    PacketAssignment,
    PreferencesKeys,
    QuestionnaireElementKeys,
    Survey
} from '@/constants/state'
import {joinIfValid} from '@/utilities/utils'
import {AfsEvents} from '@/constants/events'
import {RefToModule} from '@/classes/ElementReferenceArgs'

export default {
    props: {
        parentEnabled: {type: Boolean, default: ()=>true},
        entry: Object,
    },
    data: () => {
        return {
            invalidItems: [],
            pValid: false,
            refsUpdated: false,
            //TODO: Check Question ID's for non survey
            latentExaminationsQuestionnaires: ['IPExaminationsBeta', 'IPExaminations'],
            latentQualityQuestionIds: [
                'LPBETA-03_LATVAL', 'LPBETA-04_SUITAFIS', 'LPBETA-04_VERIFYVAL',
                'LPIP03_LATVAL', 'LPIP04_SUITAFIS', 'LPIP04_VERIFYVAL',
                'LPBETA-01_LATVAL', 'LPBETA-02_SUITAFIS', 'LPBETA-02_VERIFYVAL',
                'LPIP01_LATVAL', 'LPIP02_SUITAFIS', 'LPIP02_VERIFYVAL',
            ]
        }
    },
    mixins: [afsParsedElement],
    computed: {
        ...mapState(AfsVuexNamespaces.Surveys.mapKey, [Survey.SURVEY_ID, Survey.ENTRIES, Survey.ALWAYS_SHOW_VALIDITY]),
        ...mapGetters(AfsVuexNamespaces.Surveys.mapKey, {
            [Survey.ELEMENTS_BY_PARENT]: Survey.ELEMENTS_BY_PARENT.getter,
            latentExemplarViewFlag: 'latentExemplarViewFlag',
            afsEngineDense: 'afsEngineDense',
        }),
        ...mapState(AfsVuexNamespaces.Assignments.mapKey, [PacketAssignment.ASSIGNMENT_ID]),
        ...mapGetters(AfsVuexNamespaces.Assignments.mapKey, {
            [PacketAssignment.CURRENT.getter]: PacketAssignment.CURRENT.getter
        }),
        ...mapState(AfsVuexNamespaces.General.mapKey, [General.AFS_ELEMENT_MODULES]),
        ...mapGetters(AfsVuexNamespaces.General.mapKey, {
            [General.REF_AS_MODULE]: General.REF_AS_MODULE.getter
        }),
        ...mapState(AfsVuexNamespaces.Preferences.mapKey, [PreferencesKeys.EXPANDED_BY_DEFAULT]),
        elementStoreName: {
            get: function(){
                const refId = this[General.REF_AS_MODULE](
                    new RefToModule({
                        questionnaireId: this.entry.SurveyID,
                        parentId: this.entry.ParentID,
                        itemId: this.entry.ItemID
                    }))
                return refId
            }
        },
        conditionalOn: function () {
            const retVal = this.$store.getters[`${this.elementStoreName}/${QuestionnaireElementKeys.CONDITIONAL_ON.getter}`]
            return retVal
        },
        references: function () {
            const retVal =  this.$store.getters[`${this.elementStoreName}/${QuestionnaireElementKeys.REFERENCES.getter}`]
            return retVal || []
        },
        subReferences: () => {
            return []
        },
        enabled: function () {
            const retVal = this.$store.getters[`${this.elementStoreName}/${QuestionnaireElementKeys.ENABLED.getter}`]
            if (this.latentExaminationsQuestionnaires.indexOf(this.surveyId) >=0 && this.latentQualityQuestionIds.indexOf(this.itemId) >= 0)
                return retVal && !this.latentExemplarViewFlag
            return retVal
        },
        conditionalText: function () {
            return this.entry?.ConditionalOn || JSON.stringify({})
        },
        entryContent: function () {
            const retVal =  this.$store.getters[`${this.elementStoreName}/${QuestionnaireElementKeys.ENTRY_CONTENT.getter}`]
            return retVal || ''
        },
        fullId: function () {
            const retVal = this.$store.getters[`${this.elementStoreName}/${QuestionnaireElementKeys.ID.getter}`]
            return retVal || ''
        },
        isRequired: function () {
            const retVal = this.$store.getters[`${this.elementStoreName}/${QuestionnaireElementKeys.IS_REQUIRED.getter}`]
            return retVal || true
        },
        isTerminating: function () {
            const retVal = this.$store.getters[`${this.elementStoreName}/${QuestionnaireElementKeys.IS_TERMINATING.getter}`]
            return retVal || false
        },
        itemId: function () {
            const retVal = this.$store.getters[`${this.elementStoreName}/${QuestionnaireElementKeys.ITEM_ID.getter}`]
            return retVal || ''
        },
        itemType: function () {
            const retVal = this.$store.getters[`${this.elementStoreName}/${QuestionnaireElementKeys.ITEM_TYPE.getter}`]
            return retVal || ''
        },
        parentId: function () {
            return this.$store.getters[`${this.elementStoreName}/${QuestionnaireElementKeys.PARENT_ID.getter}`]
        },
        rowId: function () {
            return this.$store.getters[`${this.elementStoreName}/${QuestionnaireElementKeys.ROW_ID.getter}`]
        },
        surveyId: function () {
            return this.$store.getters[`${this.elementStoreName}/${QuestionnaireElementKeys.SURVEY_ID.getter}`]
        },
        surveyKey: function () {
            return this[QuestionnaireElementKeys.SURVEY_KEY.sanitized]
        },
        referenceId: function() {
            return joinIfValid({vals:[this.parentId, this.itemId]})
        },
        valid: function(){
            return this.$store.getters[`${this.elementStoreName}/${QuestionnaireElementKeys.VALID.getter}`]
        },
        statusColor: function(){
            // // A touch hacky don't set color to error on initial load
            // if(!this.$el)
            //     return ''

            if(!this.enabled || !this[Survey.ALWAYS_SHOW_VALIDITY])
                return ''

            if (!this.valid)
                return 'error'
            return this[Survey.ALWAYS_SHOW_VALIDITY]? 'success': ''
        },
        currentPacketId: function(){
            return this[PacketAssignment.CURRENT.getter]?.Packet || ''
        },
        questionnaireElement: function(){
            return this.$store[this.elementStoreName]
        },
        response: {
            get:function(){
                if(!this.$store.state[this.elementStoreName])
                    return []
                return this.$store.state[this.elementStoreName][QuestionnaireElementKeys.RESPONSE.toString()]
            },
            set:function(newVal){
                this.$store.dispatch(`${this.elementStoreName}/${QuestionnaireElementKeys.RESPONSE.updater}`, newVal)
            },
        },
        defaultChildrenExpanded: function(){
            if (!this.$store.state[this.elementStoreName]) return []
            return this.$store.getters[`${this.elementStoreName}/${QuestionnaireElementKeys.CHILDREN_ALWAYS_EXPANDED.getter}`]
        },
    },
    methods: {
        itemValidityChanged: function (val){
            const index = this.invalidItems.indexOf(val.referenceId)
            if(index >= 0 && val.value)
                this.invalidItems.splice(index, 1)
            else if (index < 0 && !val.value)
                this.invalidItems.push(val.referenceId)
        },
        cmpOldNew: function(newVal, oldVal){//}, prop=''){
            if(!this.$store.state[this.elementStoreName])
                return
            const tCmp = {
                newRet: JSON.stringify(newVal),
                oldRet: JSON.stringify(oldVal),
            }
            if(tCmp.newRet !== tCmp.oldRet){
                // tCmp.msg = `Values changed: ${prop? ' for ': ''}${prop}: old: ${tCmp.oldRet}  new: ${tCmp.newRet}`
                console.log(tCmp.msg)
            }
        }
    },
    watch: {
        enabled: function(newVal){
            this.$emit(AfsEvents.EnabledChanged, newVal)
        },
        valid: function (newVal){
            this.$emit(AfsEvents.VALID_CHANGED, {referenceId: this.referenceId, value: newVal})
        },
    },
}
