<template>
    <v-skeleton-loader :loading="loading" v-if="loading"  :types="{'table-row': 'table-cell, button', 'table-thead': 'table-cell@2'}" type="heading, table-thead, table-row@3"></v-skeleton-loader>
    <v-container v-else>
        <v-row class="text-h3 text-center justify-center">{{study}} Participant Homepage</v-row>
        <v-row>
            <v-col>
                <v-text-field label="Participant Id" :value="participantId" readonly></v-text-field>
            </v-col>
            <v-col>
                <v-text-field label="Email" :value="email" readonly></v-text-field>
            </v-col>
        </v-row>
        <v-row v-for="questionnaire in questionnaires" :key="questionnaire.surveyId">
            <v-col>{{questionnaire.Title}}</v-col>
            <v-col>
                <v-btn v-if="questionnaire.ClosedDate" color="dark" outlined @click="$router.push(questionnaire.Url)" disabled>{{ targetText(questionnaire) }}</v-btn>
                <v-btn v-else color="primary" @click="$router.push(questionnaire.Url)" :disabled="questionnaire.Disabled">{{ targetText(questionnaire) }}</v-btn>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import {AfsVuexNamespaces, General, UserInfo} from '@/constants/state'
import afsSessionRefresh from '@/mixins/afsSessionRefresh'
import {afsApi} from '@/utilities/api'
import strftime from 'strftime'
import {QuestionnaireProgress} from '@/constants/AfsEngineTypes'

export default {
    name: 'HomeView',
    mixins: [afsSessionRefresh],
    props: {
        study: {
            type: String,
            default: process.env.VUE_APP_AFS_ENGINE_STUDY,
        },
        title: {
            type: String,
            default: process.env.VUE_APP_AFS_ENGINE_TITLE,
        },
        skeletonLoaderTypes: {
            'afs-title': 'text',
            'afs-home-questionnaire': 'text, button',
            'afs-button': 'button',
            'table-row': 'table-cell@2',
        }
    },
    data: () => {
        return {
            questionnaires: [],
            loading: false
        }
    },
    computed:{
        ...mapGetters(AfsVuexNamespaces.User.mapKey, {
            [UserInfo.ID_TOKEN]: UserInfo.ID_TOKEN.getter
        })
    },
    methods: {
        ...mapActions(AfsVuexNamespaces.User.mapKey, {
            [UserInfo.COGNITO_USER.updater]: UserInfo.COGNITO_USER.updater,
        }),
        ...mapActions(AfsVuexNamespaces.General.mapKey, [General.NAV_BAR.updater]),
        targetText: function(questionnaire){
            let cat = questionnaire.Category || 'Questionnaire'
            let status = questionnaire.Progress?.Status || QuestionnaireProgress.NOT_STARTED
            let output = ''
            let disabled = false

            if(questionnaire.ClosedDate){
                const timeStr = strftime('%e %B %Y', new Date(questionnaire.ClosedDate))
                output = `${cat} closed on ${timeStr}`
            }
            else {
                switch (status.trim().toLowerCase()) {
                    case QuestionnaireProgress.IN_PROGRESS.toLowerCase():
                        output = `Resume ${cat}`
                        break
                    case QuestionnaireProgress.FINISHED.toLowerCase():
                        output = `${cat} Completed`
                        disabled = true
                        break
                    case QuestionnaireProgress.DISABLED.toLowerCase():
                        output = `${cat} Disabled`
                        disabled = true
                        break
                    case QuestionnaireProgress.NOT_STARTED.toLowerCase():
                    default:
                        output = `Start ${cat}`
                        break
                }
                this.$set(questionnaire, 'Disabled', disabled)
            }
            return output
        },
        async postData(url = '', data = {}) {
            // Default options are marked with *
            const response = await fetch(url, {
                method: 'POST', // *GET, POST, PUT, DELETE, etc.
                mode: 'cors', // no-cors, *cors, same-origin
                cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                credentials: 'same-origin', // include, *same-origin, omit
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: this[UserInfo.ID_TOKEN],
                    // 'Content-Type': 'application/x-www-form-urlencoded',
                },
                redirect: 'follow', // manual, *follow, error
                referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
                body: JSON.stringify(data) // body data type must match "Content-Type" header
            })
            return response.json() // parses JSON response into native JavaScript objects
        },
        async retrieveQuestionnaires(){
            this.loading = true
            const questionnaires = await afsApi.getQuestionnaires()
            this.questionnaires = questionnaires
            this.loading = false
        }
    },
    async created(){
        if(this[UserInfo.ID_TOKEN]) {
            await this.retrieveQuestionnaires()
            await this[General.NAV_BAR.updater](true)
        }
        else {
            await this.$router.push('/login')
        }
    },
    watch: {
        [UserInfo.ID_TOKEN]: async function(newVal){
            if(newVal)
                await this.retrieveQuestionnaires()
        }
    }
}
</script>

<style scoped>
.v-skeleton-loader {
    width: 100%;
    padding: 12px;
    margin-right: auto;
    margin-left: auto;
    max-width: 1185px;
}
>>>.v-skeleton-loader__heading{
    height: 2rem;
    margin-right: auto;
    margin-left: auto;

}
>>>.v-skeleton-loader__table-cell {
    width: 30rem !important;
}
>>>.v-skeleton-loader__button {
    width: 20rem !important;
}

</style>