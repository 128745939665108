<template>
    <v-textarea v-if="questionType.trim().toLowerCase() === questionTypes.FREE_TEXT.toLowerCase()" v-model="localValue" :disabled="!enabled" @blur="onBlurCb" rows="3"></v-textarea>
    <vue-tel-input-vuetify v-else-if="questionType.trim().toLowerCase() === questionTypes.PHONE_NUMBER.toLowerCase()"
                           mode="international" label=""  placeholder="" default-country="us" single-line
                           v-model="localValue" :disabled="!enabled" :only-countries="allowedCountries"
                           @blur="onBlurCb" />
    <v-text-field v-else v-model="localValue" :type="inputType" :rules="validators()" :disabled="!enabled" @blur="onBlurCb"></v-text-field>
</template>

<script>
import AfsSurveyItem from '@/mixins/afsSurveyItem'
import { QuestionTypes } from '@/constants/AfsEngineTypes'
import {AfsEngineQuestionValidators} from '@/classes/afsQuestionValidator'
import {mapGetters, mapState} from 'vuex'
import {AfsVuexNamespaces, General, QuestionnaireElementKeys, Survey} from '@/constants/state'
import {RefToModule} from '@/classes/ElementReferenceArgs'

export default {
    name: 'AfsAnswerText',
    mixins: [AfsSurveyItem],
    props: {
        questionType: String,
        value: {
            type: String,
            required: true,
        },
    },
    data: () => ({
        localValue: '',
        allowedCountries: ['us', 'ca'],
    }),
    computed: {
        ...mapGetters(AfsVuexNamespaces.Surveys.mapKey, {[Survey.IS_REGISTRATION]: Survey.IS_REGISTRATION.getter}),
        ...mapState(AfsVuexNamespaces.Surveys.mapKey, [Survey.ENTRIES]),
        questionTypes: () => {
            return QuestionTypes
        },
        inputType: function(){
            switch(this.questionType.trim().toLowerCase()){
                case QuestionTypes.PASSWORD.trim().toLowerCase():
                    return 'password'

                case QuestionTypes.FREE_TEXT.trim().toLowerCase():
                case QuestionTypes.NUM_TEXT.trim().toLowerCase():
                case QuestionTypes.TEXT.trim().toLowerCase():
                case QuestionTypes.NUMERIC_TEXT.trim().toLowerCase():
                case QuestionTypes.SCI_NOTATION.trim().toLowerCase():
                    return 'text'

                case QuestionTypes.SINGLE_SELECT.trim().toLowerCase():
                case QuestionTypes.MULTI_SELECT.trim().toLowerCase():
                default:
                    console.warn(`Handling question type ${this.questionType} as a text field.`)
                    return 'text'
            }
        },
    },
    methods: {
        validators: function (){
            const validator = AfsEngineQuestionValidators[this.questionType.trim().toLowerCase()]
            const self = this

            if (validator){
                let retVal =  [
                    () => {
                        let retVal = validator.validation(self.localValue)
                        if(!retVal && !self.isRequired && self.localValue.length === 0)
                            retVal = true

                        retVal = retVal ? true: false
                        return retVal ? true: validator.helpText
                    }
                ]
                // HAX: If current ID looks like 'PWCONFIRM' find the 'PW' field and make sure they match
                if(this[Survey.IS_REGISTRATION] && this.itemId.match(/_PWCONFIRM$/)){
                    let pw = Object.values(this[Survey.ENTRIES])
                        .filter(x => `${x.QuestionType}`.trim().toLowerCase() === QuestionTypes.PASSWORD.toLowerCase() && x.ItemID.match(/_PW$/))

                    pw = pw.map(x => {
                        const resId = self.$store.getters[`${AfsVuexNamespaces.General.mapKey}${General.REF_AS_MODULE.getter}`](
                            new RefToModule({
                                questionnaireId: self.surveyId,
                                itemId: x.ItemID
                            })
                        )
                        if(!self.$store.state[resId])
                            return ''
                        const response = self.$store.state[resId][QuestionnaireElementKeys.RESPONSE]
                        if(response.length < 1)
                            return ''
                        return response[0].AnswerText
                    })
                    if(pw.length === 1) {
                        retVal.push(() => {
                            const tRet = self.localValue === pw[0] ? true : 'Passwords must match'
                            return tRet
                        })
                    }
                }
                return retVal
            }
            return [()=>true]
        },
        onErrorChangeCB: function(arg){
            this.valid = arg? false: true
        },
        onBlurCb: function() {
            // this.valid = ((this.localValue && this.localValue.length > 0) && this.validators().every(x=>x()===true) )|| false
            const newVal = [{AnswerText: this.localValue.trim()}]
            this.response = newVal
        }
    },
    watch:{
        response: {
            handler: function(newVal){
                if(newVal.length < 1)
                    this.localValue = ''
                if(this.localValue === newVal[0].AnswerText)
                    return
                this.localValue = newVal[0].AnswerText.trim()
            }
        }
    },
    mounted() {
        if(this.response && this.response.length > 0)
            this.localValue = (this.response[0]?.AnswerText || '').trim()
    }
}
</script>

<style scoped></style>
