import {AfsVuexNamespaces, LoginState, Snackstand, Status, Survey, UserInfo} from '@/constants/state'
import {
    AuthenticationDetails,
    CognitoUser,
    CognitoUserPool,
} from 'amazon-cognito-identity-js'
import { promisify } from 'util'
import {createEnum} from '@/utilities/utils'
import {SnackbarMessage, SnackbarStatuses} from '@/classes/SnackbarMessage'
import {afsApi} from '@/utilities/api'
import Vue from 'vue'
import router from '@/router'

const userPool = new CognitoUserPool({
    UserPoolId: process.env.VUE_APP_AFS_COGNITO_USER_POOL_ID,
    ClientId: process.env.VUE_APP_AFS_COGNITO_USER_POOL_CLIENT_ID
})

const AuthenticationStates = createEnum(['onSuccess', 'onFailure', 'mfaSetup', 'associateSecretCode', 'selectMFAType', 'totpRequired', 'mfaRequired',] )

const authenticatePromised = (cognitoUser, authDetails) => {
    return new Promise((resolve, reject)=>{
        cognitoUser.authenticateUser(authDetails, {
            onSuccess: (data)=>{
                resolve({state: AuthenticationStates.onSuccess, data: data})
            },
            mfaSetup: (data)=>{
                resolve({state: AuthenticationStates.mfaSetup, data: data})
            },
            associateSecretCode: (data)=>{
                resolve({state: AuthenticationStates.associateSecretCode, data: data})
            },
            selectMFAType: (data)=>{
                resolve({state: AuthenticationStates.selectMFAType, data: data})
            },
            totpRequired: (data)=>{
                resolve({state: AuthenticationStates.totpRequired, data: data})
            },
            mfaRequired: (data)=>{
                resolve({state: AuthenticationStates.mfaRequired, data: data})
            },
            onFailure: (err)=>{
                reject({state: AuthenticationStates.onFailure, data: err})
            },
        })
    })
}
const sendMfaPromised = (cognitoUser, otp) =>{
    return new Promise((resolve, reject)=>{
        cognitoUser.sendMFACode(otp, {
            onSuccess: (data) =>{
                resolve(data)
            },
            onFailure: (err)=>{
                reject(err)
            }
        })
    })
}

const updateSessionPromised   = (userPool) => {
    return new Promise((resolve, reject) => {
        const User = userPool.getCurrentUser()
        if(User){
            User.getSession((err, session) => {
                if(err) reject(err)
                else resolve(session)
            })
        }
    })
}

let state = () =>{
    return {
        [UserInfo.NAME]: '',
        [UserInfo.EMAIL]: '',
        [UserInfo.PASSWORD]: '',
        [UserInfo.PARTICIPANT_ID]: '',
        [UserInfo.ENTER_OTP]: false,
        [UserInfo.SIGNUP]: false,
        [UserInfo.COGNITO_USER]: null,
        [UserInfo.SIGNUP_COMPLETE]: false,
        [UserInfo.SESSION]: {},
        [UserInfo.ACCOUNT_INFO]: {},
        [UserInfo.LOGGED_IN]: false,
        [UserInfo.LOGIN_STATE]: LoginState.NONE.toString(),
        [UserInfo.MAX_MFA_ATTEMPTS]: 5,
        [UserInfo.MFA_ATTEMPTS]: 0,
        ['cogUsr']: {}
    }
}

const defaultOptions = state()

let mutations = {
    [UserInfo.LOGIN_STATE.setter](state, payload){
        // Should be logged in @ this point
        state[UserInfo.LOGIN_STATE] = payload
        const logged_in = payload === LoginState.SUCCESS.toString()
        window.localStorage.setItem(UserInfo.LOGGED_IN, `${logged_in}`)
    },
    ...[UserInfo.ENTER_OTP, UserInfo.SIGNUP, UserInfo.COGNITO_USER, UserInfo.SIGNUP_COMPLETE, UserInfo.MFA_ATTEMPTS,
        UserInfo.EMAIL, UserInfo.PASSWORD].reduce(
        (acc, ele) => {
            acc[ele.setter] = (state, payload) => {
                state[ele] = payload
            }
            return acc
        }, []
    ),
    // [UserInfo.ENTER_OTP.setter](state, payload){
    //     state[UserInfo.ENTER_OTP] = payload
    // },
    // [UserInfo.SIGNUP.setter](state, payload){
    //     state[UserInfo.SIGNUP] = payload
    // },
    // [UserInfo.COGNITO_USER.setter](state, payload){
    //     state[UserInfo.COGNITO_USER] = payload
    // },
    // [UserInfo.SIGNUP_COMPLETE.setter](state, payload){
    //     state[UserInfo.SIGNUP_COMPLETE] = payload
    // },
    // [UserInfo.MFA_ATTEMPTS.setter](state, payload){
    //     state[UserInfo.MFA_ATTEMPTS] = payload
    // },
    [UserInfo.SESSION.setter](state, payload){
        Vue.set(state, UserInfo.SESSION, payload)
        state[UserInfo.LOGIN_STATE] = LoginState.SUCCESS.toString()
    },
    [UserInfo.ACCOUNT_INFO.setter](state, payload){
        state[UserInfo.ACCOUNT_INFO] = payload
        state[UserInfo.EMAIL] = payload.email.trim().toLowerCase()
        state[UserInfo.PARTICIPANT_ID] = payload.participantId.trim()
    },
    [Status.CLEAR.setter](state){
        const currentUser = userPool.getCurrentUser()
        if(currentUser)
            currentUser.signOut()
        for (const [key, value] of Object.entries(defaultOptions))
            state[key] = value
        window.localStorage.setItem(UserInfo.LOGGED_IN, 'false')
    },
}

let getters = {
    [UserInfo.LOGGED_IN.getter]: (state) => {
        const retVal = state[UserInfo.LOGIN_STATE] === LoginState.SUCCESS.toString()
        return retVal
    },
    [UserInfo.ID_TOKEN.getter]: (state) => {
        const session = state[UserInfo.SESSION]
        const token = session?.getIdToken?.call? (session?.getIdToken()?.getJwtToken() || session?.getIdToken()): undefined
        return token || ''
    },
    // [UserInfo.COGNITO_USER.getter]: (state) => {
    //     const needsRefreshed = !!state[UserInfo.EMAIL] || !!state[UserInfo.PASSWORD] || true
    //     if(needsRefreshed && state[UserInfo.EMAIL]) {
    //         let cognitoUser =  new CognitoUser({
    //             Username: state[UserInfo.EMAIL],
    //             Pool: userPool
    //         })
    //         cognitoUser.getSession((err, data) => {
    //             if(err) cognitoUser = null
    //             else cognitoUser = data
    //         })
    //         return cognitoUser
    //     }
    //     return null
    // }
}

let actions = {
    async [AfsVuexNamespaces.User.initializer]({commit, dispatch, state}, payload) {
        let phoneNumber = payload.phoneNumber.replaceAll(/[^0-9]/g, '')
        phoneNumber = `+${phoneNumber}`

        const userAttributes =  [
            {
                Name: 'email',
                Value: payload.email,
            },
            {
                Name: 'given_name',
                Value: payload.firstName,
            },
            {
                Name: 'family_name',
                Value: payload.lastName,
            },
            {
                Name: 'phone_number',
                Value: phoneNumber,
            }
        ]
        try {
            if(payload.labName)
                userAttributes.push({Name: 'lab_name', Value: payload.labName})
            const promisifiedSignUp = promisify(userPool.signUp).bind(userPool)
            const resp = await promisifiedSignUp(payload.email, payload.password, userAttributes, null)
            console.log(`resp: ${resp} state: ${state}`)
            commit(UserInfo.EMAIL.setter, payload.email.trim().toLowerCase())
            commit(UserInfo.PASSWORD.setter, payload.password)
            commit(UserInfo.COGNITO_USER.setter, new CognitoUser({
                Username: payload.email,
                Pool: userPool
            }))
            commit(UserInfo.LOGIN_STATE.setter, LoginState.ACCOUNT_CREATED.toString())
            dispatch(UserInfo.ENTER_OTP.toggle)
        }
        catch (e){
            console.warn(e)
            dispatch(`${AfsVuexNamespaces.Snackstand.mapKey}${Snackstand.MESSAGES.insertAction}`,
                new SnackbarMessage({
                    state: SnackbarStatuses.ERROR,
                    message: `Unable to create account. ${e.message}`
                }),
                {root: true}
            )
            commit(UserInfo.LOGIN_STATE.setter, Status.ERROR.toString())
        }
    },
    async [UserInfo.LOGIN_STATE.updater]({commit, state, dispatch}, payload){
        var authenticationDetails = new AuthenticationDetails({
            Username: payload.email,
            Password: payload.password
        })
        const cognitoUser = new CognitoUser({
            Username: payload.email,
            Pool: userPool
        })

        try {
            const resp = await authenticatePromised(cognitoUser, authenticationDetails)
            commit(UserInfo.EMAIL.setter, payload.email.trim().toLowerCase())
            commit(UserInfo.PASSWORD.setter, payload.password)
            commit(UserInfo.COGNITO_USER.setter, cognitoUser)
            state.cogUsr = cognitoUser
            if (resp.state === AuthenticationStates.mfaRequired)
                dispatch(UserInfo.ENTER_OTP.toggle)
            console.log(`resp: ${resp}`)
            return true
        }
        catch (e){
            dispatch(`${AfsVuexNamespaces.Snackstand.mapKey}${Snackstand.MESSAGES.insertAction}`,
                new SnackbarMessage({
                    state: SnackbarStatuses.ERROR,
                    message: 'Unable to login. Bad email or password.'
                }),
                {root: true}
            )
            commit(UserInfo.LOGIN_STATE.setter, Status.ERROR.toString())
            return false
        }
    },
    async [UserInfo.ENTER_OTP.toggle]({commit, state}) {
        commit(UserInfo.ENTER_OTP.setter, !state[UserInfo.ENTER_OTP])
    },
    async [UserInfo.ENTER_OTP.updater]({commit, dispatch, state, rootGetters}, payload){
        // let cognitoUser = new CognitoUser({
        //     Username:  state[UserInfo.EMAIL],
        //     Pool: userPool
        // })
        const cognitoUser = state[UserInfo.COGNITO_USER]
        try {
            if (state[UserInfo.SIGNUP]) {
                const promisifiedConfirmation = promisify(cognitoUser.confirmRegistration).bind(cognitoUser)
                await promisifiedConfirmation(payload, true)
                commit(UserInfo.SIGNUP.setter, false)
                commit(UserInfo.SIGNUP_COMPLETE.setter, true)
                // const resId = `${AfsVuexNamespaces.Surveys.mapKey}${Survey.REGISTRATION.getter}`
                // const regData = rootGetters[resId]
                // notify the user a second MFA is required
                dispatch(`${AfsVuexNamespaces.Snackstand.mapKey}${Snackstand.MESSAGES.insertAction}`,
                    new SnackbarMessage({
                        state: SnackbarStatuses.INFO,
                        message: 'Logging in to save responses. Please wait for a new verification code before continuing.'
                    }),
                    {root: true}
                )
                commit(UserInfo.LOGIN_STATE.setter, LoginState.VERIFIED.toString())
                // dispatch(UserInfo.LOGIN_STATE.updater, {email: regData.email, password: regData.password})
                dispatch(UserInfo.LOGIN_STATE.updater, {
                    email: state[UserInfo.EMAIL],
                    password: state[UserInfo.PASSWORD]
                })
                await dispatch(UserInfo.COGNITO_USER.updater)
                commit(UserInfo.ENTER_OTP.setter, false)
                commit(UserInfo.MFA_ATTEMPTS.setter, 0)
            }
            else {
                // await sendMfaPromised(state.cogUsr, payload)
                await sendMfaPromised(cognitoUser, payload)
                commit(UserInfo.ENTER_OTP.setter, false)
                commit(UserInfo.MFA_ATTEMPTS.setter, 0)
            }
        }
        catch(e){
            console.warn(e)
            dispatch(`${AfsVuexNamespaces.Snackstand.mapKey}${Snackstand.MESSAGES.insertAction}`,
                new SnackbarMessage({
                    state: SnackbarStatuses.ERROR,
                    message: 'Bad verification code entered.'
                }),
                {root: true}
            )
            // commit(UserInfo.LOGIN_STATE.setter, Status.ERROR.toString())
            commit(UserInfo.MFA_ATTEMPTS.setter, state[UserInfo.MFA_ATTEMPTS] + 1)
            console.log(`enter_otp: set attempts: ${state[UserInfo.MFA_ATTEMPTS]} state: ${state[UserInfo.ENTER_OTP]}`)
            commit(UserInfo.ENTER_OTP.setter, false)
            console.log(`enter_otp: set otp attempts: ${state[UserInfo.MFA_ATTEMPTS]} state: ${state[UserInfo.ENTER_OTP]}`)
            console.log(`enter_otp: login state: ${state[UserInfo.LOGIN_STATE]} verified: ${state[LoginState.VERIFIED]} is verif: ${state[UserInfo.LOGIN_STATE] === LoginState.VERIFIED}`)
            // commit(UserInfo.ENTER_OTP.updater, true)
            // await dispatch(UserInfo.ENTER_OTP.updater, true)
            if(state[UserInfo.MFA_ATTEMPTS] < state[UserInfo.MAX_MFA_ATTEMPTS]) {
                if (state[UserInfo.SIGNUP]) {
                    const promisifiedConfirmation = promisify(cognitoUser.resendConfirmationCode).bind(cognitoUser)
                    await promisifiedConfirmation()
                    await dispatch(UserInfo.ENTER_OTP.toggle)
                }
                else if (state[UserInfo.LOGIN_STATE] === LoginState.VERIFIED.toString()) {
                    const resId = `${AfsVuexNamespaces.Surveys.mapKey}${Survey.REGISTRATION.getter}`
                    const regData = rootGetters[resId]
                    await dispatch(UserInfo.LOGIN_STATE.updater, {email: regData.email, password: regData.password})
                }
                else {
                    commit(UserInfo.PASSWORD.setter, '')
                }
            }
            else {
                commit(UserInfo.PASSWORD.setter, '')
            }
            console.log(`enter_otp: set otp attempts: ${state[UserInfo.MFA_ATTEMPTS]} state: ${state[UserInfo.ENTER_OTP]}`)
            return
        }

        dispatch(UserInfo.COGNITO_USER.updater)
        dispatch(UserInfo.SESSION.updater)
    },
    async [UserInfo.COGNITO_USER.updater]({commit}){
        const currentUser = userPool.getCurrentUser()
        if (!currentUser)
            return

        const getSessionPromised = (cognitoUser) => {
            return new Promise((resolve, reject)=>{
                cognitoUser.getSession((err, data) => {
                    if(err) reject(err)
                    else resolve(data)
                })
            })
        }
        const resp = await getSessionPromised(currentUser)
        commit(UserInfo.COGNITO_USER.setter, resp)
    },
    async [UserInfo.SESSION.updater]({commit, dispatch, state}){
        try {
            const session = await updateSessionPromised(userPool)
            commit(UserInfo.SESSION.setter, session)
            if (!state[UserInfo.ACCOUNT_INFO] || Object.keys(state[UserInfo.ACCOUNT_INFO]).length < 1)
                dispatch(UserInfo.ACCOUNT_INFO.updater)
        }
        catch (e){
            const dispatchKey = `${AfsVuexNamespaces.Snackstand.mapKey}${Snackstand.MESSAGES.insertAction}`
            dispatch(dispatchKey, new SnackbarMessage({
                state: SnackbarStatuses.ERROR,
                message: 'Unable to refresh session. Redirecting to login.'
            }))
        }
    },
    async [UserInfo.ACCOUNT_INFO.updater]({commit}){
        const resp = await afsApi.getAccountInfo()
        commit(UserInfo.ACCOUNT_INFO.setter, resp)
    },
    [Status.CLEAR.updater]({commit}, payload){
        commit(Status.CLEAR.setter, payload)
    },
    [UserInfo.LOGOUT](){
        router.push('/')
    },
}

export default {
    namespaced: true,
    state: state,
    getters: getters,
    mutations: mutations,
    actions: actions,
}