<template>
    <v-container>
        <v-row>
            <v-col class="text-h3 align-center text-center">
                Welcome to the {{ title }}
            </v-col>
        </v-row>
        <v-divider class="col-12 mt-1" />
        <v-row>
            <v-col class="text-h6 col-12">Sign In</v-col>
            <v-col class="col-12 mt-0 pt-0">
                If you have already registered for {{ study }}, you can sign in
                to resume your progress using the form below.
            </v-col>
            <v-form class="col-12" ref="loginForm" lazy-validation>
                <v-text-field
                        v-model="email"
                        :rules="[rules.required, formOK ]"
                        type="text"
                        name="afs-login-email"
                        label="Email"
                        placeholder="user@example.com"
                        hint="Your email address"
                        class="col-12"
                        :color="dynamicColor"
                ></v-text-field>
                <v-text-field
                        v-model="password"
                        :append-icon="showPwd ? 'mdi-eye' : 'mdi-eye-off'"
                        :rules="[rules.required, rules.min, formOK]"
                        :type="showPwd ? 'text' : 'password'"
                        name="afs-login-pwd"
                        label="Password"
                        counter
                        @click:append="showPwd = !showPwd"
                        class="col-12"
                        @keyup.enter="loginBtnClick"
                        :color="dynamicColor"
                ></v-text-field>
                <v-btn color="primary" depressed @click="loginBtnClick">Sign In</v-btn>
            </v-form>
        </v-row>
        <v-divider class="col-12 mt-3" />
        <v-row>
            <span class="afs-markdown-gen" v-html="format(preamble)"></span>
        </v-row>
        <v-row>
            If you wish to register for the {{ study }} study, please
            register via the button below.
        </v-row>
        <v-row>
            <v-btn color="primary" depressed @click="gotoRegistration">Register</v-btn>
        </v-row>
        <v-row class="mt-8">
            To view instructions, FAQs, and other documentation please click
            on one of the following links
        </v-row>
        <v-row>
            <ul>
                <afs-list  v-for="item in referencedDocuments" :key="item.title + '2'" mode="list" :entry="item" />
            </ul>
        </v-row>
        <EnterMfa />
    </v-container>
</template>

<script>
import {mapState, mapGetters, mapActions} from 'vuex'
import {AfsVuexNamespaces, General, Status, UserInfo} from '@/constants/state'
import EnterMfa from '@/components/EnterMfa'
import AfsParsedElement from '@/mixins/afsParsedElement'
import AfsList from '@/components/AfsList'

export default {
    name: 'LoginView',
    components: {AfsList, EnterMfa, },
    mixins: [AfsParsedElement],
    props: {
        study: {
            type: String,
            default: process.env.VUE_APP_AFS_ENGINE_STUDY,
        },
        title: {
            type: String,
            default: process.env.VUE_APP_AFS_ENGINE_TITLE,
        },
        preamble: {
            type: String,
            default: process.env.VUE_APP_PREAMBLE,
        },
        documents: {
            type: Array,
            default: () => [],
        },
        legal: String,
    },
    data: () => {
        return {
            foo: 0,
            email: '',
            password: '',
            showPwd: false,
            rules: {
                required: (value) => (!!value || 'Required.'),
                min: (v) => (v.length >= 12 || 'Min 12 characters'),
                emailMatch: () => ('The email and password you entered don\'t match'),
                checkOk: (ok) => ok
            },
        }
    },
    computed: {
        ...mapState(AfsVuexNamespaces.User.mapKey, [UserInfo.NAME, UserInfo.LOGIN_STATE]),
        ...mapGetters(AfsVuexNamespaces.User.mapKey, {
            [UserInfo.LOGGED_IN]: UserInfo.LOGGED_IN.getter
        }),
        ...mapState(AfsVuexNamespaces.General.mapKey, [General.DATA_FILES]),
        referencedDocuments: function () {
            return this[General.DATA_FILES]
        },
        dynamicColor: function(){
            if(!this.ok)
                return 'error'
            return ''
        },
        ok: function(){
            const ret_val = `${this[UserInfo.LOGIN_STATE]}`.toLowerCase() !== Status.ERROR.toLowerCase()
            return ret_val
        },
    },
    methods: {
        ...mapActions(AfsVuexNamespaces.User.mapKey, {
            [UserInfo.LOGIN_STATE.updater]: UserInfo.LOGIN_STATE.updater
        }),
        ...mapActions({[Status.CLEAR.updater]: Status.CLEAR.updater}),
        ...mapActions(AfsVuexNamespaces.General.mapKey,{[General.DATA_FILES.updater]: General.DATA_FILES.updater}),
        gotoRegistration: function () {
            this.$router.push('/survey/registration')
        },
        loginBtnClick: function(){
            this[UserInfo.LOGIN_STATE.updater]({
                email: this.email,
                password: this.password
            })
        },
        formOK: function(){
            // HAX: This is just here to keep email/pwd fields reactive when a bad email/pwd is entered
            return this.ok
        },
        validate () {
            this.$refs.loginForm.validate()
        },
        reset () {
            this.$refs.loginForm.reset()
        },
        resetValidation () {
            this.$refs.loginForm.resetValidation()
        },
    },
    watch:{
        [UserInfo.LOGGED_IN](val){
            if (val)
                this.$router.push('/home')
        },
        ok: function(){
            this.validate()
        },
    },
    mounted() {
        this[Status.CLEAR.updater]()
        this[General.DATA_FILES.updater]()
    }
}
</script>
