import {mapActions, mapGetters, mapState} from 'vuex'
import {AfsVuexNamespaces, UserInfo} from '@/constants/state'

export default {
    name: 'AfsSessionRefresh',
    computed: {
        ...mapState(AfsVuexNamespaces.User.mapKey, [UserInfo.SESSION, UserInfo.EMAIL, UserInfo.PARTICIPANT_ID]),
        ...mapGetters(AfsVuexNamespaces.User.mapKey, {
            [UserInfo.ID_TOKEN]: UserInfo.ID_TOKEN.getter,
            [UserInfo.LOGGED_IN]: UserInfo.LOGGED_IN.getter,
        }),
        email: function(){ return this[UserInfo.EMAIL] },
        participantId: function(){ return this[UserInfo.PARTICIPANT_ID] },
    },
    methods: {
        ...mapActions(AfsVuexNamespaces.User.mapKey, [UserInfo.SESSION.updater])
    },
    watch:{
        [UserInfo.ID_TOKEN]: function(newVal,  oldVal){
            if(oldVal && !newVal)
                console.log('LOGOUT')
        }
    },
}