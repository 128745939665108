<template>
    <v-container class="afs-latent-container fill-height px-0 pt-0 mt-0" @contextmenu.prevent="false" fluid>
        <v-container fluid class="tabGradient fill-height px-0 pt-0">
            <v-container fluid id="exercisePanel" class="fill-height">
                <v-row id="imageview_wrap" ref="imageViewWrap" class="fill-height">
                    <latent-image :zoom-times="zoomValue" :shared-scale="sharedScale"
                                  :rotation-steps="latentRotation" :tool-mode="tool"
                                  :latent-image-type="latentImageMode"
                                  :show-markup="showMarkup" :latent-packet-mode="LatentPacketModes.LATENT"
                                  ref="latentComponent"  @imageScaleChanged="imageScaleChangedCB"/>

                    <latent-image v-if="latentExemplarViewFlag" :zoom-times="zoomValue" :shared-scale="sharedScale"
                                  :rotation-steps="exemplarRotation" :tool-mode="tool"
                                  :latent-image-type="exemplarImageMode"
                                  :show-markup="showMarkup" :latent-packet-mode="LatentPacketModes.EXEMPLAR"
                                  ref="exemplarComponent" @imageScaleChanged="imageScaleChangedCB"/>
                </v-row>
                <v-row id="exercises_toolbar_base" class="toolbar_base">
                    <v-col>
                        <v-row>
                            <span class="d-flex text-center mt-2 text-uppercase mr-1 font-weight-bold">Latent</span>
                            <v-select
                                    v-model="latentImageMode"
                                    :items="items"
                                    dense
                                    single-line
                                    class="max-width-9rem"
                            />
                            <v-btn @click="latentRotation -= 1" icon>
                                <v-icon>mdi-file-rotate-left-outline</v-icon>
                            </v-btn>
                            <v-btn @click="latentRotation += 1" icon>
                                <v-icon>mdi-file-rotate-right-outline</v-icon>
                            </v-btn>
                            <v-spacer/>
                        </v-row>
                    </v-col>
                    <v-col class="justify-center">
                        <v-row justify="center">
                            <v-btn @click="zoomValue -= 0.5" icon>
                                <v-icon>mdi-magnify-minus-outline</v-icon>
                            </v-btn>
                            <v-btn @click="zoomValue += 0.5" icon>
                                <v-icon>mdi-magnify-plus-outline</v-icon>
                            </v-btn>
                            <v-btn @click="tool = 'HAND'" icon :color="tool === 'HAND' ? 'primary' : ''">
                                <v-icon>mdi-hand-back-right</v-icon>
                            </v-btn>
                            <v-btn @click="tool = 'DOT'" icon :color="tool === 'DOT' ? 'primary' : ''">
                                <v-icon>mdi-draw</v-icon>
                            </v-btn>
                            <v-btn @click="tool = 'DEL'" icon :color="tool === 'DEL' ? 'primary' : ''">
                                <v-icon>mdi-delete</v-icon>
                            </v-btn>
                            <v-btn @click="reset()" icon>
                                <v-icon>mdi-autorenew</v-icon>
                            </v-btn>
                            <v-checkbox
                                    v-model="showMarkup"
                                    :label="'Show Markup'"
                            ></v-checkbox>
                        </v-row>
                    </v-col>
                    <v-col v-if="latentExemplarViewFlag" class="justify-end">
                        <v-row class="justify-end">
                            <v-spacer/>
                            <span class="d-flex text-center mt-2 text-uppercase mr-1 font-weight-bold">Exemplar</span>
                            <v-select
                                    v-model="exemplarImageMode"
                                    :items="items"
                                    dense
                                    single-line
                                    class="max-width-9rem"
                            ></v-select>
                            <v-btn @click="exemplarRotation -= 1" icon v-show="latentExemplarViewFlag"
                                   class="justify-end">
                                <v-icon>mdi-file-rotate-left-outline</v-icon>
                            </v-btn>
                            <v-btn @click="exemplarRotation += 1" icon v-show="latentExemplarViewFlag"
                                   class="justify-end">
                                <v-icon>mdi-file-rotate-right-outline</v-icon>
                            </v-btn>
                        </v-row>
                    </v-col>
                </v-row>
            </v-container> <!-- end imageview_wrap -->
        </v-container>
    </v-container>
</template>

<script>
import {AfsVuexNamespaces, PacketAssignment, Survey, General} from '@/constants/state'
import {mapGetters, mapState} from 'vuex'
import {PacketFileTypes, LatentPacketModes} from '@/constants/PacketFileTypes'
import LatentImage from '@/components/LatentImage'


export default {
    name: 'LatentExamplarCanvas',
    components: {LatentImage},
    data: function () {
        return {
            latentRotation: 0,
            latentImageMode: PacketFileTypes.BASELINE,
            exemplarRotation: 0,
            exemplarImageMode: PacketFileTypes.BASELINE,
            thumbBoxColor: 'blue',
            thumbBoxWeight: 5,
            ROTSTEPS: 16,
            RotDegrees: 360,
            zoomValue: 0,
            tool: 'HAND',
            showMarkup: true,
            linkZoomsFlag: true,
            items: [
                {text: 'Baseline image', value: PacketFileTypes.BASELINE},
                {text: 'Dark image', value: PacketFileTypes.DARK},
                {text: 'EQ image', value: PacketFileTypes.EQ},
                {text: 'Inverse image', value: PacketFileTypes.INVERSE},
                {text: 'Light image', value: PacketFileTypes.LIGHT}
            ],
            latentScale: 1,
            exemplarScale: 1,
        }
    },
    computed: {
        ...mapState(AfsVuexNamespaces.Surveys.mapKey, {
            [Survey.SURVEY_ID]: Survey.SURVEY_ID,
        }),
        ...mapGetters(AfsVuexNamespaces.Surveys.mapKey, {
            [Survey.RESPONSES]: Survey.RESPONSES.getter,
            latentExemplarViewFlag: 'latentExemplarViewFlag'
        }),
        ...mapState(AfsVuexNamespaces.Assignments.mapKey, [PacketAssignment.CURRENT_INDEX, PacketAssignment.ASSIGNMENT_ID]),
        ...mapGetters(AfsVuexNamespaces.Assignments.mapKey, {
            [PacketAssignment.CURRENT]: PacketAssignment.CURRENT.getter,
            [PacketAssignment.NUM_PACKETS]: PacketAssignment.NUM_PACKETS.getter,
        }),
        ...mapGetters(AfsVuexNamespaces.General.mapKey, {[General.REF_AS_MODULE]: General.REF_AS_MODULE.getter}),
        LatentPacketModes() {
            return LatentPacketModes
        },
        sharedScale: function(){
            if(this.latentExemplarViewFlag)
                return this.exemplarScale
            return this.latentScale
        }
    },
    methods: {
        reset() {
            this.zoomValue = 0
            this.latentRotation = 0
            this.latentImageMode = PacketFileTypes.BASELINE
            this.exemplarRotation = 0
            this.exemplarImageMode = PacketFileTypes.BASELINE
            this.$refs.latentComponent.reset()
            this.$refs.exemplarComponent?.reset()
        },
        imageScaleChangedCB: function (event) {
            switch(event.mode.trim().toLowerCase()){
                case LatentPacketModes.LATENT.toLowerCase():
                    this.latentScale = event.scale
                    break
                case LatentPacketModes.EXEMPLAR.toLowerCase():
                    this.exemplarScale = event.scale
                    break
                default:
                    console.info(`Unexpected latent mode: ${event.mode}`)
                    break
            }
        },
    },
}

</script>

<style scoped>
.v-input--selection-controls {
    margin-top: 4px !important;
}

.afs-latent-container {
    min-width: 70rem;
}

.toolbar_base {
    margin-top: 20px;
}

.max-width-9rem {
    max-width: 10rem;
}


</style>
