export function ensureObject(item) {
    if (typeof item === 'object') return item

    try {
        return JSON.parse(item)
    }
    catch {
        return null
    }
}

export function joinIfValid({ vals = [], joinStr = '.' } = {}) {
    if (!vals) return ''
    const popVals = vals.reduce((acc, ele) => {
        if (ele) acc.push(ele)
        return acc
    }, [])
    return popVals.join(joinStr)
}

export function caseInsensitiveMapper(obj) {
    const asObj = ensureObject(obj)
    let keys = Object.keys(asObj)
    const ret = keys.reduce((acc, ele) => {
        acc[ele.trim().toLowerCase()] = ele
        return acc
    }, {})

    return ret
}

export class CaseInsensitiveTranslator {
    #prop_map
    #data

    constructor(cpy) {
        this.#data = ensureObject(cpy)
        this.#prop_map = caseInsensitiveMapper(this.#data)
        return new Proxy(this, {
            get: (obj, key) => {
                try {
                    const safeKey = `${key}`.trim().toLowerCase()
                    const propKey = this.#prop_map[safeKey]
                    return obj.#data[propKey]
                }
                catch(e){
                    // intentionally blank
                }
            },
        })
    }
}

export function createEnum(values){
    const retVal = values.reduce((acc, ele)=>{
        acc[ele] = ele
        return acc
    },{})
    return Object.freeze(retVal)
}

export function toPascalCase(val){
    return `${val}`.trim().replace(/(\w)(\w*)/g,
        function(g0,g1,g2){return g1.toUpperCase() + g2.toLowerCase()})
}
