<template>
    <v-expansion-panel :ref="referenceId"  :disabled="!enabled">
        <v-expansion-panel-header :ref="headerRef" :error="!valid">
            <template v-slot:default>
                <v-row class="align-center">
                    <v-chip class="ma-0 pa-0 afs-status-indicator" :color="statusColor" :active="!!statusColor" label></v-chip>
                    <v-col
                            class="afs-markdown-gen"
                            v-html="formatted"
                    ></v-col>
                </v-row>
            </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
            <template
                v-if="
                    questionType.trim().toLowerCase() ===
                    questionTypes.SINGLE_SELECT.trim().toLowerCase()
                "
            >
                <afs-answer-radio :parent-enabled="enabled" :entry="entry" v-model="radioAnswer" @blur="onBlur" @validchanged="onValidChanged">

                </afs-answer-radio>
            </template>
            <template
                v-else-if="
                    questionType.trim().toLowerCase() ===
                    questionTypes.MULTI_SELECT.trim().toLowerCase()
                "
            >
                <afs-answer-checkbox :parent-enabled="enabled" :entry="entry" v-model="chkAnswer" @blur="onBlur" @validchanged="onValidChanged">

                </afs-answer-checkbox>
            </template>
            <template v-else-if="questionType">
                <afs-answer-text
                    v-model="txtAnswer"
                    :entry="entry"
                    :questionType="questionType"
                    :parent-enabled="enabled"
                    @blur="onBlur"
                    @validchanged="onValidChanged"
                ></afs-answer-text>
            </template>
        </v-expansion-panel-content>
    </v-expansion-panel>
</template>

<script>
import {mapState, mapActions} from 'vuex'
import afsSurveyItem from '@/mixins/afsSurveyItem'
import {QuestionTypes} from '@/constants/AfsEngineTypes'
import AfsAnswerCheckbox from '@/components/AfsAnswerCheckbox'
import AfsAnswerRadio from '@/components/AfsAnswerRadio'
import AfsAnswerText from '@/components/AfsAnswerText'
import {AfsVuexNamespaces, PreferencesKeys, QuestionnaireElementKeys, Survey} from '@/constants/state'
import {AfsEvents} from '@/constants/events'

export default {
    name: 'AfsSurveyQuestion',
    mixins: [afsSurveyItem],
    components: {AfsAnswerCheckbox, AfsAnswerRadio, AfsAnswerText},
    data: () => ({
        questionTypes: QuestionTypes,
        radioAnswer: '',
        chkAnswer: [],
        txtAnswer: '',
        pExpanded: undefined,
        model: false,
    }),
    computed: {
        ...mapState(AfsVuexNamespaces.Surveys.mapKey, [Survey.IS_REGISTRATION]),
        headerRef: function(){
            return `hdr.${this.referenceId}`
        },
        answerItems: function () {
            return this.entry?.Answers
        },
        questionType: function () {
            return this.$store.getters[`${this.elementStoreName}/${QuestionnaireElementKeys.QUESTION_TYPE.getter}`]
        },
        localAnswer: {
            get() {
                let retVal = []
                let isTerminating = false
                switch (this.questionType) {
                    case QuestionTypes.MULTI_SELECT: {
                        const answerSet = new Set(this.chkAnswer)
                        retVal = this.answerItems
                            .filter((ele) => answerSet.has(ele.ItemID))
                            .reduce((acc, ele) => {
                                acc.push({
                                    AnswerId: ele.ItemID,
                                    AnswerText: ele.EntryContent,
                                })
                                if(ele.IsTerminating)
                                    isTerminating = true
                                return acc
                            }, [])
                        break
                    }
                    case QuestionTypes.SINGLE_SELECT: {
                        const answerItem = this.answerItems.filter(
                            (ele) => ele.ItemID === this.radioAnswer
                        )
                        if (answerItem.length > 1) {
                            throw new Error(
                                `Found ${answerItem.length} answers for question ${this.parentId}.${this.itemId}`
                            )
                        }
                        answerItem.splice(1)
                        isTerminating =  (answerItem[0] || {}).IsTerminating ? true: false
                        retVal = answerItem.map((ele) => ({
                            AnswerId: ele.ItemID,
                            AnswerText: ele.EntryContent,
                        }))
                        break
                    }
                    case QuestionTypes.TEXT:
                    case QuestionTypes.FREE_TEXT:
                    case QuestionTypes.SCI_NOTATION:
                    case QuestionTypes.NUM_TEXT:
                        retVal = [{
                            AnswerText: this.txtAnswer.trim(),
                        }]
                        isTerminating = this.isTerminating
                        break
                    default:
                        retVal = []
                }
                if(isTerminating)
                    this[Survey.IS_TERMINATING.updater](true)
                return retVal
            }
            ,
        },
        expanded: {
            get: function(){
                if(!this.enabled)
                    return false
                if(this.pExpanded === undefined)
                    return this[PreferencesKeys.EXPANDED_BY_DEFAULT]
                return this.pExpanded
            },
            set: function(newVal){
                this.pExpanded = newVal
            }
        }
    },
    methods: {
        ...mapActions(AfsVuexNamespaces.Surveys.mapKey, {
            [Survey.IS_TERMINATING.updater]: Survey.IS_TERMINATING.updater,
        }),
        onBlur: function(){
        },
        onValidChanged: function($event){
            this.pValid = $event.value
            this.$emit(AfsEvents.VALID_CHANGED, {referenceId: this.referenceId, value: $event.value})
        }
    },
    watch: {
        localAnswer: {
            handler(newVal) {
                this.$emit('input', newVal)
                if(JSON.stringify(this.response) !== JSON.stringify(newVal))
                    this.response = newVal
            },
            deep: true,
        },
        valid: function(newVal){
            this.$emit(AfsEvents.VALID_CHANGED, {referenceId: this.referenceId, value: newVal})
        },
    },
}
</script>

<style scoped>
.afs-markdown-gen >>> p:only-child {
    margin-bottom: 4px;
}
</style>
