<template>
    <div class="afs-snackstand d-flex flex-column">
        <template v-for="(item, index) in pagedMessages">
            <v-snackbar v-if="index < maxVisible" :key="item.id" :ref="item.id" v-model="visibleMap[item.id]" :color="item.color"
                        :timeout="item.timeout" @input="snackCloseCb(item.id, $event)" top right>
                <template v-slot:action="{ attrs }">
                    <v-btn
                            text
                            class="p-0 text-h4"
                            v-bind="attrs"
                            @click="snackCloseBtn(item.id, $event)"
                    >
                        &times;
                    </v-btn>
                </template>
                {{ item.message }}
            </v-snackbar>
        </template>
    </div>
</template>

<script>
import {mapActions, mapGetters, mapState} from 'vuex'
import {AfsVuexNamespaces, Snackstand} from '@/constants/state'

export default {
    name: 'AfsSnackstand',
    data: () => {
        return {
            visibleMap: {},
        }
    },
    props: {
        maxVisible: {
            type: Number,
            default: 5,
        }
    },
    computed: {
        ...mapState(AfsVuexNamespaces.Snackstand.mapKey, {messages: Snackstand.MESSAGES}),
        ...mapGetters(AfsVuexNamespaces.Snackstand.mapKey, {pagedMessages: Snackstand.WINDOW.getter})
    },
    methods: {
        ...mapActions(AfsVuexNamespaces.Snackstand.mapKey, {
            [Snackstand.MESSAGES.removeAction]: Snackstand.MESSAGES.removeAction,
            [Snackstand.PAGE_SIZE.updater]: Snackstand.PAGE_SIZE.updater,
        }),
        snackCloseCb: function (id, $event) {
            console.log(`AfsSnackstand.snackCloseCb: id: ${id} event: ${$event}`)
            this[Snackstand.MESSAGES.removeAction](id)
        },
        snackCloseBtn: function(id, $event){
            console.log(`AfsSnackstand.snackCloseBtn: id: ${id} event: ${$event}`)
            this.$set(this.visibleMap, id, false)
            this[Snackstand.MESSAGES.removeAction](id)
        }
    },
    watch: {
        messages: {
            handler: function(newVal){
                for(const msg of newVal){
                    if(!Object.prototype.hasOwnProperty.call(this.visibleMap, msg.id))
                        this.$set(this.visibleMap, msg.id, true)
                }
            },
            deep: true
        },
        maxVisible: function (val){
            if(val > 0 && val <20)
                this[Snackstand.PAGE_SIZE.updater](val)
        }
    }
}
</script>

<style scoped>

.afs-snackstand {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 10;
}

.afs-snackstand .v-snack {
    position: unset !important;
    top: unset !important;
    height: unset !important;
}
</style>