<template>
    <v-skeleton-loader :loading="loading" v-if="loading"  :types="{'table-row': 'paragraph', 'table-thead': 'table-cell@3'}" type="heading, table-thead, table-row@3, button"></v-skeleton-loader>
    <v-container fluid class="px-14" :class="dynamicClasses" v-else>
        <v-row class="text-h3 text-center  justify-center">{{ title }}</v-row>
        <v-row v-if="participantId">
            <!-- <v-col v-if="currentPacketId">
                <afs-packet-activator />
            </v-col> -->
            <v-col>
                <v-text-field label="Participant Id" :value="participantId" readonly hide-details></v-text-field>
            </v-col>
            <v-col>
                <v-text-field label="Email" :value="email" readonly hide-details></v-text-field>
            </v-col>
        </v-row>
        <!-- <v-row v-if="currentPacketId">
            <v-col>
                <v-img :src="packetProofSheet" />
            </v-col>
        </v-row>
        -->
        <v-row v-if="packetDescription">
            <v-col class="afs-markdown-gen" v-html="format(packetDescription)">
            </v-col>
        </v-row>
        <template v-if="navigationMode.trim().toLowerCase() === 'carousel'">
            <v-carousel
                    :continuous="false"
                    :cycle="false"
                    hide-delimiters
                    hide-delimiter-background
                    delimiter-icon="mdi-minus"
                    height="auto"
                    v-model="currentCarouselPage"
                    :dense="afsEngineDense"
            >
                <template v-slot:prev="{ on, attrs }">
                    <v-btn color="primary" v-bind="attrs" v-on="on" aria-label="Previous Section" small fab>
                        <v-icon>mdi-chevron-left</v-icon>
                    </v-btn>
                </template>
                <template v-slot:next="{ on, attrs }">
                    <v-btn color="primary" v-bind="attrs" v-on="on"  aria-label="Next Section" small fab>
                        <v-icon>mdi-chevron-right</v-icon>
                    </v-btn>
                </template>
                <v-carousel-item
                        v-for="val in SrvyElementsByParent(null)"
                        :key="`afsSurvey.${val.ParentID}-${val.ItemID}`"
                        :parent-id="val.ParentID"
                        :ref="`${val.ParentID}.${val.ItemID}`"
                        eager
                        :class="spacingClasses"
                >
                    <v-expansion-panels v-model="expandedPanels[val.ItemID]" multiple accordion>
                        <afs-survey-section :entry="val" @enabledchanged="enabledChangedCb(val.ItemID, $event)"
                                            @input="valuesChanged"
                                            @validchanged="itemValidityChanged"></afs-survey-section>
                    </v-expansion-panels>
                </v-carousel-item>
            </v-carousel>
        </template>

        <template v-else>
            <v-row
                    v-for="val in SrvyElementsByParent(null)"
                    :key="`afsSurvey.${val.ParentID}-${val.ItemID}`"
                    :parent-id="val.ParentID"
                    :ref="`${val.ParentID}.${val.ItemID}`"
            >
                <v-expansion-panels v-model="expandedPanels[val.ItemID]" multiple accordion>
                    <afs-survey-section :entry="val"
                                        @validchanged="itemValidityChanged" @input="valuesChanged"></afs-survey-section>
                </v-expansion-panels>
            </v-row>
        </template>
        <v-row class="mb-2">
            <v-btn class="mt-3" :color="statusColor" :disabled="!reviewEnabled" depressed @click="reviewBtnClick">Review</v-btn>
        </v-row>
        <EnterMfa/>
        <afs-error-redirect/>
        <afs-review v-model="reviewCloseReason" v-bind:imageSrc="packetProofSheet"/>
        <latent-examplar-canvas v-if="currentPacketId !== ''"></latent-examplar-canvas>
    </v-container>
</template>

<script>
import {mapActions, mapGetters, mapMutations, mapState} from 'vuex'
import {
    AfsVuexNamespaces, General, LoginState,
    PacketAssignment, PreferencesKeys,
    Snackstand, Status,
    Survey,
    UserInfo
} from '@/constants/state'
import LatentExamplarCanvas from '@/components/LatentExamplarCanvas'
import AfsSurveySection from '@/components/AfsSurveySection'
import EnterMfa from '@/components/EnterMfa'
import AfsErrorRedirect from '@/components/AfsErrorRedirect'
import AfsReview from '@/components/AfsReview'
import AfsSessionRefresh from '@/mixins/afsSessionRefresh'
import {afsApi} from '@/utilities/api'
import {SnackbarMessage, SnackbarStatuses} from '@/classes/SnackbarMessage'
// import AfsPacketActivator from '@/components/AfsPacketActivator'
import {RefToModule} from '@/classes/ElementReferenceArgs'
import QuestionnaireElement from '@/store/QuestionnaireElement'
import {joinIfValid} from '@/utilities/utils'
import AfsParsedElement from '@/mixins/afsParsedElement'
import {AfsEngineSurveyNavigationMode} from '@/constants/AfsEngineSurveyNavigationMode'
import {AfsEngineTransactionLogTypes} from '@/constants/AfsEngineTypes'

export default {
    name: 'AfsSurvey',
    mixins: [AfsSessionRefresh, AfsParsedElement],
    components: {AfsReview, AfsErrorRedirect, EnterMfa, AfsSurveySection, LatentExamplarCanvas},
    data: function () {
        return {
            resMod: {},
            expandedPanels: {},
            invalidItems: [],
            checkValid: false,
            reviewCloseReason: null,
            packetProofSheet: '',
            packetDescription: '',
            needSave: false,
            saveValuesTimer: null,
            saveValueTimeoutSec: 60,
            creating: false,
            refreshingPacket: false,
            submitting: false,
            currentCarouselPage: 0,
        }
    },
    computed: {
        ...mapState(AfsVuexNamespaces.Surveys.mapKey, {
            [Survey.SURVEY_ID]: Survey.SURVEY_ID,
            [Survey.ENTRIES]: Survey.ENTRIES,
            [Survey.SURVEY_TITLE]: Survey.SURVEY_TITLE,
            [Survey.NAVIGATION_MODE]: Survey.NAVIGATION_MODE,
            [Survey.ALWAYS_SHOW_VALIDITY]: Survey.ALWAYS_SHOW_VALIDITY,
            title: [Survey.SURVEY_TITLE],
            navigationMode: [Survey.NAVIGATION_MODE],
        }),
        ...mapGetters(AfsVuexNamespaces.Surveys.mapKey, {
            [Survey.ELEMENTS_BY_PARENT]: Survey.ELEMENTS_BY_PARENT.getter,
            [Survey.IS_REGISTRATION]: Survey.IS_REGISTRATION.getter,
            [Survey.REGISTRATION]: Survey.REGISTRATION.getter,
            [Survey.REGISTRATION_KEYS]: Survey.REGISTRATION_KEYS.getter,
            [Survey.COMPLETE.getter]: Survey.COMPLETE.getter,
            [Survey.RESPONSES]: Survey.RESPONSES.getter,
            [Survey.TOPS_ALWAYS_EXPANDED]: Survey.TOPS_ALWAYS_EXPANDED.getter,
            [Survey.TOPS_COMPLETE]: Survey.TOPS_COMPLETE.getter,
            latentExemplarViewFlag: 'latentExemplarViewFlag',
            afsEngineDense: 'afsEngineDense',
        }),
        ...mapState(AfsVuexNamespaces.User.mapKey, [
            UserInfo.ENTER_OTP,
            UserInfo.SIGNUP_COMPLETE,
            UserInfo.MFA_ATTEMPTS,
            UserInfo.MAX_MFA_ATTEMPTS,
            UserInfo.LOGIN_STATE,
        ]),
        ...mapGetters(AfsVuexNamespaces.User.mapKey, [UserInfo.LOGGED_IN.getter]),
        ...mapState(AfsVuexNamespaces.Assignments.mapKey, [PacketAssignment.CURRENT_INDEX, PacketAssignment.ASSIGNMENT_ID]),
        ...mapGetters(AfsVuexNamespaces.Assignments.mapKey, {
            [PacketAssignment.CURRENT]: PacketAssignment.CURRENT.getter,
            [PacketAssignment.NUM_PACKETS]: PacketAssignment.NUM_PACKETS.getter,
            [PacketAssignment.COMPLETE]: PacketAssignment.COMPLETE.getter,
        }),
        ...mapGetters(AfsVuexNamespaces.General.mapKey, {[General.REF_AS_MODULE]: General.REF_AS_MODULE.getter}),
        ...mapState(AfsVuexNamespaces.Preferences.mapKey, [PreferencesKeys.EXPANDED_BY_DEFAULT]),
        currentPacketId: function(){
            return this[PacketAssignment.CURRENT]?.Packet || ''
        },
        complete: function(){
            return this[Survey.COMPLETE.getter]
        },
        loading: function(){
            if(this.creating || this.refreshingPacket || this.submitting)
                return true
            return false
        },
        reviewEnabled: function(){
            if(!(this.navigationMode.trim().toLowerCase() === AfsEngineSurveyNavigationMode.CAROUSEL.toLowerCase()))
                return true
            return this.currentCarouselPage + 1 >= this[Survey.TOPS_COMPLETE].length
        },
        statusColor: function(){
            if(!this[Survey.ALWAYS_SHOW_VALIDITY])
                return 'primary'
            if(this.complete)
                return 'success'
            return 'error'
        },
        spacingClasses: function(){
            let classes = ['mx-10']
            if (this.afsEngineDense)
                classes.push('my-1')
            else
                classes.push('my-8')
            return classes.join(' ')
        },
        dynamicClasses: function(){
            let classes = []
            // Taking a shortcut since this is only set for examination questionnaires
            if(this.afsEngineDense)
                classes.push('fill-height')
            return classes.join(' ')
        },
    },
    methods: {
        ...mapActions(AfsVuexNamespaces.User.mapKey, {
            [AfsVuexNamespaces.User.initializer]: AfsVuexNamespaces.User.initializer,
            [UserInfo.ENTER_OTP.updater]: UserInfo.ENTER_OTP.updater,
        }),
        ...mapMutations(AfsVuexNamespaces.User.mapKey, [UserInfo.SIGNUP.setter]),
        ...mapActions(AfsVuexNamespaces.Surveys.mapKey, {
            [Survey.SHOW_REVIEW.toggle]: Survey.SHOW_REVIEW.toggle,
            [Survey.IS_TERMINATING.updater]: Survey.IS_TERMINATING.updater,
            [Survey.ALWAYS_SHOW_VALIDITY.updater]: Survey.ALWAYS_SHOW_VALIDITY.updater,
        }),
        ...mapActions(AfsVuexNamespaces.Snackstand.mapKey, {[Snackstand.MESSAGES.insertAction]: Snackstand.MESSAGES.insertAction}),
        ...mapActions(AfsVuexNamespaces.Assignments.mapKey, {
            [PacketAssignment.NEXT]: PacketAssignment.NEXT.updater
        }),
        enabledChangedCb: function (index, event) {
            const alwaysExpanded = this[Survey.TOPS_ALWAYS_EXPANDED].indexOf(index) > 0
            if (!event && !alwaysExpanded)
                this.$set(this.expandedPanels, index, [])
            else if(alwaysExpanded)
                this.$set(this.expandedPanels, index, [0])
        },
        async reviewBtnClick() {
            if(!this.complete){
                await this[Snackstand.MESSAGES.insertAction](
                    new SnackbarMessage({
                        state: SnackbarStatuses.ERROR,
                        message: 'Errors detected, please answer flagged questions,'
                    }),
                )
                await this[Survey.ALWAYS_SHOW_VALIDITY.updater](true)
                return
            }
            this.clearSaveValuesTimer()
            this.reviewCloseReason = null
            this[Survey.SHOW_REVIEW.toggle]()
        },
        itemValidityChanged: function (val) {
            const index = this.invalidItems.indexOf(val.referenceId)
            if (index >= 0 && val.value)
                this.invalidItems.splice(index, 1)
            else if (index < 0 && !val.value)
                this.invalidItems.push(val.referenceId)
        },
        valuesChanged: function(){ //(val){
            this.needSave = true
            this.setupClearSaveValuesTimer()
        },
        setupClearSaveValuesTimer: function(){
            this.clearSaveValuesTimer()
            if(!this[UserInfo.LOGGED_IN])
                return
            this.saveValuesTimer = setTimeout(this.saveValuesTimerCB, this.saveValueTimeoutSec*1000)
        },
        clearSaveValuesTimer: function(){
            if(this.saveValuesTimer)
                clearTimeout(this.saveValuesTimer)
            if(!this[UserInfo.LOGGED_IN])
                return
            this.saveValuesTimer = null
        },
        saveValuesTimerCB: async function(){
            await this.submitResponses(true)
        },
        submitResponses: async function(in_progress=false){
            if(in_progress && !this.needSave)
                return
            const self = this
            const submission = {
                results: this[Survey.RESPONSES],
                surveyId: this[Survey.SURVEY_ID],
                packetId: this[PacketAssignment.CURRENT]?.Packet,
                in_progress: in_progress
            }
            self.clearSaveValuesTimer()
            try {
                if (!in_progress)
                    this.submitting = true
                const ok = await afsApi.submitResults(submission)
                if (ok)
                    self.needSave = false
                else
                    self.setupClearSaveValuesTimer()
                if (!in_progress && ok) {
                    if(this[PacketAssignment.COMPLETE]) {
                        await self.$router.push('/home')
                    }
                    else {
                        await this[PacketAssignment.NEXT]()
                        this.currentCarouselPage = 0
                    }
                }
            }
            catch{
                self[Snackstand.MESSAGES.insertAction](
                    new SnackbarMessage({
                        state: SnackbarStatuses.ERROR,
                        message: 'Unable to save progress,'
                    }),
                )
                self.setupClearSaveValuesTimer()
            }
            finally {
                this.submitting = false
            }
        },
    },
    watch: {
        [PacketAssignment.CURRENT]: async function(){
            if(!this.currentPacketId){
                this.packetProofSheet = ''
                this.packetDescription = ''
            }
            this.refreshingPacket = true
            const self = this
            const promises = [
                afsApi.getPacketProofSheet(this[Survey.SURVEY_ID], '', this[PacketAssignment.ASSIGNMENT_ID], this.currentPacketId),
                afsApi.getPacketDescription(this[Survey.SURVEY_ID], '', this[PacketAssignment.ASSIGNMENT_ID], this.currentPacketId),
                this[Survey.ALWAYS_SHOW_VALIDITY.updater](false)
            ]
            if(!this.creating) {
                (Object.values(this[Survey.ENTRIES])).forEach(ele => {
                    try {
                        const resId = self[General.REF_AS_MODULE](
                            new RefToModule({
                                questionnaireId: ele.SurveyID,
                                parentId: ele.ParentID,
                                itemId: ele.ItemID
                            })
                        )
                        const tInit = self.$store.dispatch(`${resId}/${Status.CLEAR.updater}`)
                        promises.push(tInit)
                    }
                    catch (e) {
                        console.warn(e)
                    }
                }, [])
            }
            const responses = await Promise.all(promises)
            this.packetProofSheet = responses[0]
            this.packetDescription = responses[1]
            this.refreshingPacket = false
        },
        reviewCloseReason: function(newVal){
            if(newVal){
                if(this[Survey.IS_REGISTRATION]){
                    this[UserInfo.SIGNUP.setter](true)
                    this[AfsVuexNamespaces.User.initializer](this[Survey.REGISTRATION])
                }
                else{
                    this.submitResponses(false)
                }
            }
            else{
                this.setupClearSaveValuesTimer()
            }
            // if(newVal === false)
            //     this.setupClearSaveValuesTimer()
            // else if(newVal === true)
            //     await this.submitResponses(false)
        },
        [Survey.TOPS_ALWAYS_EXPANDED]: function(newVal, oldVal){
            const self = this
            if(newVal) {
                newVal.forEach(function (x) {
                    self.$set(self.expandedPanels, x, [0])
                })
                // Should be a relatively short (<1k) list so probably negligible impact for being inefficient
                if (oldVal) {
                    const removed = oldVal.filter(x => newVal.indexOf(x) < 0)
                    removed.forEach(x => {
                        self.$set(self.expandedPanels, x, [])
                    })
                }
            }
        },
        [UserInfo.MFA_ATTEMPTS]: function(newVal){
            if(newVal >= this[UserInfo.MAX_MFA_ATTEMPTS])
                this[Survey.IS_TERMINATING.updater](true)
        },
        [UserInfo.LOGIN_STATE]: function (newVal){
            if(newVal === LoginState.SUCCESS.toString() && this[Survey.IS_REGISTRATION])
                this.submitResponses(false)
        },
        latentExemplarViewFlag: function (newVal, oldVal) {
            if(this.loading) return

            //Ping Backend
            if (newVal != oldVal && newVal === true) {
                afsApi.saveTransactionLog(this[Survey.SURVEY_ID], this[PacketAssignment.ASSIGNMENT_ID], this.currentPacketId, AfsEngineTransactionLogTypes.RESPONSE_LOG, 'Exemplar Seen')
                    .catch(err => {
                        console.log(err)
                    })
            }
        },
        [PreferencesKeys.EXPANDED_BY_DEFAULT]: function(newVal){
            if(newVal){
                const self = this
                this[Survey.TOPS_ALWAYS_EXPANDED]
                    .forEach(function (x) {
                        self.$set(self.expandedPanels, x, [0])
                    })
                const removed = Object.keys(this.expandedPanels).filter(x => self[Survey.TOPS_ALWAYS_EXPANDED].indexOf(x) < 0)
                removed.forEach(x => {
                    self.$set(self.expandedPanels, x, [])
                })
            }
        },
    },
    async created() {
        const self = this
        try {
            this.creating = true
            await this.$store.dispatch(`${AfsVuexNamespaces.General.mapKey}${General.AFS_ELEMENT_MODULES.updater}`, {})
            await this.$store.dispatch(`${AfsVuexNamespaces.Surveys.mapKey}${AfsVuexNamespaces.Surveys.initializer}`)
            const promises = (Object.values(this[Survey.ENTRIES])).reduce((acc, ele) => {
                try {
                    const resId = self[General.REF_AS_MODULE](
                        new RefToModule({
                            questionnaireId: ele.SurveyID,
                            parentId: ele.ParentID,
                            itemId: ele.ItemID
                        })
                    )
                    self.$store.registerModule(resId, QuestionnaireElement)
                    const mapKey = joinIfValid({
                        vals: [resId, Status.INITIALIZED.updater],
                        joinStr: '/'
                    })
                    const tInit = self.$store.dispatch(mapKey, ele)
                    acc.push(tInit)
                }
                catch (e) {
                    console.warn(e)
                }
                return acc
            }, [])
            await Promise.all(promises)
            if (this[UserInfo.LOGGED_IN.getter]) {
                await this.$store.dispatch(`${AfsVuexNamespaces.Assignments.mapKey}${AfsVuexNamespaces.Assignments.initializer}`)
                await this.$store.dispatch(`${AfsVuexNamespaces.Surveys.mapKey}${Survey.RESPONSES.initializer}`)
            }
            this.creating = false
        }
        catch(e){
            console.warn(e)
            const page = this[Survey.IS_REGISTRATION]? 'login': 'home'
            await self[Snackstand.MESSAGES.insertAction](
                new SnackbarMessage({
                    state: SnackbarStatuses.ERROR,
                    message: `Error retrieving questionnaire data, Returning to ${page} page.`
                }),
            )
            await this.$router.push(`/${page}`)
        }
    },
    beforeMount: function () {
        this.expandedPanels = this[Survey.ELEMENTS_BY_PARENT](null).reduce((acc, ele) => {
            acc[ele.ItemID] = []
            return acc
        }, {})
    },
    mounted() {
        const self = this
        this[Survey.TOPS_ALWAYS_EXPANDED].forEach(function(x){
            self.$set(self.expandedPanels, x, [0])
        })
    },
    async beforeDestroy(){
        if(this.valuesChanged && this[UserInfo.LOGGED_IN]){
            this.clearSaveValuesTimer()
            await this.saveValuesTimerCB()
        }
        let self = this
        Object.keys(this.$store.state[AfsVuexNamespaces.General][General.AFS_ELEMENT_MODULES] || {}).forEach(x=>{
            if(self.$store.hasModule(x))
                self.$store.unregisterModule(x)
        })
        const promises = Object.values(AfsVuexNamespaces)
            .filter(x=> x.toString() !== AfsVuexNamespaces.User.toString())
            .map(x => {
                return self.$store.dispatch(`${x.mapKey}${Status.CLEAR.updater}`)
            })
        await Promise.all(promises)
    }
}
</script>

<style scoped>
/*.container {*/
/*    max-width: 93.75%;*/
/*}*/
.v-skeleton-loader {
    width: 100%;
    padding: 12px;
    margin-right: auto;
    margin-left: auto;
    max-width: 93.75%;
}
>>>.v-skeleton-loader__heading{
    height: 2rem;
    margin-right: auto;
    margin-left: auto;

}
>>>.v-skeleton-loader__table-cell {
    width: 30rem !important;
}
>>>.v-skeleton-loader__paragraph{
    margin-bottom: 8px;
}
>>>.v-skeleton-loader__button {
    width: 10rem !important;
}
</style>
