import { Snackstand, Status} from '@/constants/state'
import hyperid from 'hyperid'

const state = () => {
    return {
        [Snackstand.MESSAGES]: [],
        [Snackstand.PAGE_SIZE]: 5
    }
}

const getters = {
    [Snackstand.MESSAGES.getter]: (state) => {
        return state[Snackstand.MESSAGES]
    },
    [Snackstand.WINDOW.getter]: (state) => {
        return state[Snackstand.MESSAGES].slice(0, 5)
    },
}

const defaultOptions = state()

const mutations = {
    [Snackstand.MESSAGES.insertMutation]: (state, payload) => {
        if(Array.isArray(payload))
            state[Snackstand.MESSAGES].push(payload)
        else
            state[Snackstand.MESSAGES].push(payload)
    },
    [Snackstand.MESSAGES.removeMutation]: (state, payload) => {
        if(`${payload}`.trim().toLowerCase().match(/^(all|clear)$/) ) {
            state[Snackstand.MESSAGES] = []
        }
        else if (Array.isArray(payload)){
            const deleteIdxs = payload
                .map(x=> state[Snackstand.MESSAGES].findIndex(ele => ele.id === x))
                .filter(x => x >= 0)
                .sort((a,b) => b.localeCompare(a))
            for(const idx of deleteIdxs)
                state[Snackstand.MESSAGES].splice(idx, 1)
        }
        else{
            const idx = state[Snackstand.MESSAGES].findIndex(x=>x.id === payload)
            if(idx >= 0)
                state[Snackstand.MESSAGES].splice(idx, 1)
        }
    },
    [Snackstand.PAGE_SIZE.setter]: (state, payload) => {
        state[Snackstand.PAGE_SIZE] = payload
    },
    [Status.CLEAR.setter](state){
        for (const [key, value] of Object.entries(defaultOptions))
            state[key] = value
    },
}

const actions = {
    [Snackstand.MESSAGES.insertAction]: ({commit}, payload)=>{
        if(Array.isArray(payload)){
            commit(Snackstand.MESSAGES.insertMutation, payload.map(x=>{
                return {
                    id: hyperid.instance(),
                    ...x,
                }
            }))
        }
        else{
            const tid = hyperid()
            commit(Snackstand.MESSAGES.insertMutation, { id: tid, ...payload,})
        }
    },
    [Snackstand.MESSAGES.removeAction]: ({commit}, payload)=>{
        commit(Snackstand.MESSAGES.removeMutation, payload)
    },
    [Snackstand.PAGE_SIZE.updater]: ({commit}, payload) => {
        commit(Snackstand.PAGE_SIZE.setter, payload)
    },
    [Status.CLEAR.updater]({commit}, payload){
        commit(Status.CLEAR.setter, payload)
    },
}

export default {
    modules: {},
    namespaced: true,
    state: state,
    getters: getters,
    mutations: mutations,
    actions: actions
}