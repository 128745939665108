import hyperid from 'hyperid'
import {toPascalCase} from '@/utilities/utils'

export class SnackbarStatuses {
    static get ERROR() {
        return 'error'
    }

    static get WARNING() {
        return 'warning'
    }

    static get SUCCESS() {
        return 'success'
    }

    static get INFO() {
        return 'info'
    }

    static toColor(status) {
        switch (`${status}`.trim().toLowerCase()) {
            case this.ERROR.toLowerCase():
            case this.WARNING.toLowerCase():
            case this.SUCCESS.toLowerCase():
                return `${status}`.trim().toLowerCase()
            case this.INFO.toLowerCase():
                return 'primary'
            default:
                return ''
        }
    }
}


export class SnackbarMessage {
    constructor(options = {}) {
        const defaults = {
            state: 'Info',
            title: toPascalCase(options.state),
            color: SnackbarStatuses.toColor(options.state),
            message: '',
            timeout: 10000,
            id: hyperid()(),
        }

        const sanitized = {
            ...Object.keys(defaults).reduce((acc, key)=>{
                acc[key] = options[key] || defaults[key]
                return acc
            },{}),
            // ...options
        }

        Object.assign(this, sanitized)
    }

    static create() {
        if (!arguments.message)
            return null
        return new SnackbarMessage({
            ...arguments,
        })
    }
}