import * as marked from 'marked'
import DOMPurify from 'dompurify'

export default {
    name: 'AfsParser',
    methods: {
        substitute: function () {
            return null
        },
        format: function (mkdnTxt) {
            if(!mkdnTxt) return ''
            if (typeof mkdnTxt !== 'string' && !(mkdnTxt instanceof String))
                return ''
            let html = marked.parse(mkdnTxt)
            const clean = DOMPurify.sanitize(html)
            return clean
        },
    },
    computed: {
        rawText(){
            return this.entryContent || ''
        },
        formatted: {
            get: function () {
                return this.format(this.rawText)
            },
        },
    },
}
