export class RefToModule {
    static get #defaults () {
        return {
            prefix: '',
            questionnaireId: '',
            parentId: '',
            itemId: '',
            packetId: '',
            suffix: ''
        }
    }

    constructor({prefix = '', questionnaireId = '', parentId = '', itemId = '', packetId = '', suffix = ''}={}) {
        Object.assign(this, RefToModule.#defaults)
        Object.assign(this, {
            prefix: prefix,
            questionnaireId: questionnaireId,
            parentId: parentId,
            itemId: itemId,
            packetId: packetId,
            suffix: suffix
        })
    }
    
    asArray(){
        return [
            this.prefix,
            this.questionnaireId,
            // this.packetId,
            // this.parentId,
            this.itemId,
            this.suffix
        ]
    }
}