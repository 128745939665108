export class AfsEngineConditionalOperations {
    static get EQUAL() {
        return 'EQUAL'
    }

    static get EQUALS() {
        return 'EQUALS'
    }

    static get NOT_EQUAL() {
        return 'NOT_EQUAL'
    }

    static get NOT_EQUALS() {
        return 'NOT_EQUALS'
    }

    static get OR() {
        return 'OR'
    }

    static get AND() {
        return 'AND'
    }

    static get IN() {
        return 'IN'
    }
}

export const REFERENCE_REGEX = /\{([-_.\w]+)\}/
export const REFERENCE_REGEX_GLOBAL = new RegExp(/\{([-_.\w]+)\}/, 'g')
