<template>
    <v-container fluid>
        <v-row>
            <v-col>
                <v-checkbox v-model="localValue" v-for="ansItem in answerItems" :value="ansItem.ItemID" :disabled="!enabled" :key="`afsAnsCheck.${ansItem.ItemID}`" :name="`${surveyId}.${itemId}.${ansItem.ItemID}`">
                    <template v-slot:label>
                        <div class="afs-markdown-gen" v-html="format(ansItem.EntryContent)"></div>
                    </template>
                </v-checkbox>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import AfsSurveyItem from '@/mixins/afsSurveyItem'
import { QuestionTypes } from '@/constants/AfsEngineTypes'
// import {joinIfValid} from '@/utilities/utils'

export default {
    name: 'AfsAnswerCheckbox',
    mixins: [AfsSurveyItem],
    props: {
        questionType: String,
        value: {
            type: Array,
            required: true,
        },
    },
    data: function() {
        return {
            pVal: [],
            localValue: []
        }
    },
    computed: {
        answerItems: function () {
            return this.entry?.Answers
        },
        questionTypes: () => {
            return QuestionTypes
        },
        // localValue: {
        //     get() {
        //         // const resKey = joinIfValid({vals:[ this.surveyId.toLowerCase(), this.parentId]})
        //         if(this.response && this.response?.length) {
        //             const retVal = this.response.map(ele=>{return ele.AnswerId})
        //             return retVal
        //         }
        //         return []
        //     },
        //     set(value) {
        //         const toggleVal = value[value.length-1]
        //         const idx = this.pVal.indexOf(toggleVal)
        //         if (idx >= 0) this.pVal.splice(idx, 1)
        //         else this.pVal.push(toggleVal)
        //     },
        // },
    },
    watch: {
        localValue: {
            handler(newVal) {
                this.$emit('input', newVal)
            },
            deep: true,
        },
    },
    beforeMount() {
        this.pVal = this.value
    },
    mounted(){
        if(this.response && this.response?.length)
            this.localValue = this.response.map(ele=>{return ele.AnswerId})
    },
}
</script>

<style scoped></style>
