import {AfsVuexNamespaces, General, PacketAssignment, Status, Survey} from '@/constants/state'
import {afsApi} from '@/utilities/api'
import {joinIfValid} from '@/utilities/utils'
import Vue from 'vue'
import {RefToModule} from '@/classes/ElementReferenceArgs'

let state = () => {
    return {
        [General.NAV_BAR]: false,
        [General.DATA_FILES]: [],
        [General.AFS_ELEMENT_MODULES]: {},
        [Status.INITIALIZED]: false
    }
}

const defaultOptions = state()

let mutations = {
    [Status.CLEAR.setter]: function(state){
        for (const [key, value] of Object.entries(defaultOptions))
            state[key] = value
    },
    [General.NAV_BAR.setter]: function(state, payload){
        state[General.NAV_BAR] = payload? true: false
    },
    [General.DATA_FILES.setter]: function(state, payload){
        state[General.DATA_FILES] = []
        for(const i of payload)
            state[General.DATA_FILES].push(i)
    },
    [General.AFS_ELEMENT_MODULES.setter]: function(state, payload){
        if(typeof payload === 'string' || payload instanceof String)
            Vue.set(state[General.AFS_ELEMENT_MODULES], payload, true)
        else
            state[General.AFS_ELEMENT_MODULES] = payload
    },
}

let actions = {
    [Status.CLEAR.updater]: function({commit}){
        commit(Status.CLEAR.setter)
    },
    [General.NAV_BAR.updater]: function({commit}, payload){
        commit(General.NAV_BAR.setter, payload)
    },
    [General.DATA_FILES.updater]: async function({commit}){
        const data = await afsApi.getDataFiles()
        commit(General.DATA_FILES.setter, data)
    },
    [General.AFS_ELEMENT_MODULES.updater]: async function({commit}, payload){
        commit(General.AFS_ELEMENT_MODULES.setter, payload)
    },
}

let getters = {
    [General.REF_AS_MODULE.getter]: (_, __, rootState, rootGetters) => (payload) =>{
        let parts = []
        let locArgs = {}
        if(typeof payload === 'string' || payload instanceof String){
            parts = payload.split('.')
            const args = {
                questionnaireId: parts[0]
            }
            if(parts.length > 2)
                args.parentId = parts[1]
            args.itemId = joinIfValid({vals: parts.slice(parts.length > 2? 2: 1)})
            locArgs = new RefToModule(args)
        }
        else{
            locArgs = new RefToModule(payload)
        }

        if(Survey.isCurrent(locArgs.questionnaireId))
            locArgs.questionnaireId =  rootState[AfsVuexNamespaces.Surveys][Survey.SURVEY_ID].trim()
        if(!locArgs.packetId){
            let rootKey =`${AfsVuexNamespaces.Assignments.mapKey}${PacketAssignment.CURRENT.getter}`
            let packet = rootGetters[rootKey] || {}
            locArgs.packetId = packet.Packet
        }
        const retVal = joinIfValid({vals: locArgs.asArray(), joinStr: '-'})
        return retVal

        // if(typeof payload === 'string' || payload instanceof String)
        //     parts = payload.split('.')
        // else
        //     parts = payload.reference.split('.')
        //
        // // console.log(`abc: ${state}`)
        //
        // let survey = parts[0].trim().toLowerCase()
        // const itemId = joinIfValid({vals: parts.slice(parts.length > 2? 2: 1)})
        //
        // if(Survey.isCurrent(survey))
        //     survey = rootState[AfsVuexNamespaces.Surveys][Survey.SURVEY_ID].trim().toLowerCase()
        //
        // let rootKey =`${AfsVuexNamespaces.Assignments.mapKey}${PacketAssignment.CURRENT.getter}`
        // let packet = rootGetters[rootKey] || {}
        //
        // const retVal = joinIfValid({vals: [payload.prefix, survey, packet.Packet, itemId, payload.suffix], joinStr: '-'})
        //
        // return retVal
    }
}


export default {
    modules: {},
    namespaced: true,
    state: state,
    getters: getters,
    mutations: mutations,
    actions: actions,
}
