import {PreferencesKeys, Status} from '@/constants/state'

const state = () => {
    return {
        [PreferencesKeys.EXPANDED_BY_DEFAULT]: true,
    }
}

const defaults = state()

const mutations = {
    [Status.CLEAR.setter]: (state)=>{
        Object.assign(state, defaults)
    },
    [PreferencesKeys.EXPANDED_BY_DEFAULT.setter]: (state, payload) => {
        state[PreferencesKeys.EXPANDED_BY_DEFAULT] = !!payload
    }
}

const actions = {
    [Status.CLEAR.updater]: ({commit}) => {
        commit(Status.CLEAR.setter)
    },
    [PreferencesKeys.EXPANDED_BY_DEFAULT.updater]: ({commit}, payload) => {
        commit(PreferencesKeys.EXPANDED_BY_DEFAULT.setter, payload)
    },
    [PreferencesKeys.EXPANDED_BY_DEFAULT.toggle]: ({commit, state}) => {
        commit(PreferencesKeys.EXPANDED_BY_DEFAULT.setter, !state[PreferencesKeys.EXPANDED_BY_DEFAULT])
    },
}

const getters = {}

export default {
    modules: {},
    namespaced: true,
    state: state,
    mutations: mutations,
    getters: getters,
    actions: actions,
}
