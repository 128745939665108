<template>
    <!-- Study, home, Glossary, FAQ, Questions, Logout -->
    <v-app-bar app>
        <v-toolbar-title>{{ study }}</v-toolbar-title>
        <v-divider class="mx-4" vertical />
        <v-btn @click="$router.push('/home')">Home</v-btn>
        <template v-for="item in menu" >
            <v-divider class="mx-4"  :key="`divider-${item.title}`" vertical />
            <afs-list mode="menu" :entry="item" :key="`entry-${item.title}`" />
        </template>
        <v-divider class="mx-4" vertical />
        <v-btn :href="`mailto:${studyEmail}`"> Contact Us </v-btn>
        <v-spacer />
        <v-menu>
            <template v-slot:activator="{ on, attrs }">
                <v-btn
                        icon
                        v-bind="attrs"
                        v-on="on"
                >
                    <v-icon>mdi-account-circle</v-icon>
                </v-btn>
            </template>

            <v-list>
                <v-switch class="ml-2" v-model="localExpand" label="Expand All"></v-switch>
                <v-list-item
                        v-for="(item, i) in userItems"
                        :key="i"
                >
                    <v-divider v-if="item.type  === 'spacer'" />
                    <v-list-item-title v-else-if="!item.callback">{{ item.title }}</v-list-item-title>
                    <v-list-item-title v-else @click="item.callback()">{{ item.title }}</v-list-item-title>
                </v-list-item>
            </v-list>
        </v-menu>
    </v-app-bar>
</template>

<script>
import AfsList from '@/components/AfsList'
import {mapActions, mapState} from 'vuex'
import {AfsVuexNamespaces, General, PreferencesKeys, UserInfo} from '@/constants/state'

export default {
    name: 'AfsNavBar',
    components: {AfsList},
    data: () => {
        return {
            study: process.env.VUE_APP_AFS_ENGINE_STUDY,
            studyEmail: process.env.VUE_APP_AFS_ENGINE_STUDY_EMAIL,
        }
    },
    computed: {
        ...mapState(AfsVuexNamespaces.General.mapKey, [General.DATA_FILES]),
        ...mapState(AfsVuexNamespaces.User.mapKey, [UserInfo.PARTICIPANT_ID, UserInfo.EMAIL]),
        ...mapState(AfsVuexNamespaces.Preferences.mapKey, [PreferencesKeys.EXPANDED_BY_DEFAULT]),
        documents: function(){
            return this[General.DATA_FILES]
        },
        menu: function(){
            const retVal = [
                ...this.documents,

            ]
            return retVal
        },
        userItems: function (){
            const self = this
            return [
                {title: this[UserInfo.PARTICIPANT_ID]},
                {title: this[UserInfo.EMAIL]},
                {type: 'spacer'},
                {title: 'Log Out', callback: ()=>{self.$router.push('/')}}
            ]
        },
        localExpand: {
            get: function() { return this[PreferencesKeys.EXPANDED_BY_DEFAULT]},
            set: function(val) {this[PreferencesKeys.EXPANDED_BY_DEFAULT.updater](val)}
        }
    },
    methods: {
        ...mapActions(AfsVuexNamespaces.General.mapKey, {[General.DATA_FILES.updater]: General.DATA_FILES.updater}),
        ...mapActions(AfsVuexNamespaces.Preferences.mapKey, [PreferencesKeys.EXPANDED_BY_DEFAULT.updater]),
        goHome: function(){

        },
    },
    mounted() {
        this[General.DATA_FILES.updater]()
    }
}
</script>

<style scoped>

</style>