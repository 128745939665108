<template>
    <v-row justify="center">
        <v-dialog
                v-model="UsrInfoEnterOtp"
                persistent
                max-width="600px"
        >
            <v-card>
                <v-card-title>
                    <span class="text-h5">Please input verification code{{subTitle}}</span>
                </v-card-title>
                <v-card-text>
                    <v-form class="justify-center" ref="afsOtpForm" @submit="enterMfsCb" @submit.prevent>
                        <v-text-field v-model="otp" ref="afsOtpTxt"></v-text-field>
                        <v-row>
                            <v-col>This code was sent via text message to the cell phone that you used to register. </v-col>
                        </v-row>
                        <v-row>
                            <v-spacer></v-spacer>
                            <v-btn
                                    color="primary"
                                    type="submit"
                                    text
                            >
                                Done
                            </v-btn>
                        </v-row>
                    </v-form>
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-row>
</template>
<script>
import {AfsVuexNamespaces, UserInfo} from '@/constants/state'
import {mapActions, mapState} from 'vuex'

export default {
    name: 'EnterMfa',
    data: function(){
        return {
            otp: ''
        }
    },
    computed: {
        ...mapState(AfsVuexNamespaces.User.mapKey, [UserInfo.ENTER_OTP, UserInfo.SIGNUP]),
        subTitle: function(){
            if(this[UserInfo.SIGNUP])
                return ' - (Signup)'
            return ''
        }
    },
    methods: {
        ...mapActions(AfsVuexNamespaces.User.mapKey, {
            [UserInfo.ENTER_OTP.updater]: UserInfo.ENTER_OTP.updater
        }),
        enterMfsCb(){
            console.log(`completeSignUp  otp: ${this.otp}`)
            this[UserInfo.ENTER_OTP.updater](this.otp)
        },
    },
    watch: {
        [UserInfo.ENTER_OTP]: function(val){
            if(val)
                this.$refs.afsOtpForm?.reset()
        },
    },
}
</script>
