export class QuestionTypes {
    static get SINGLE_SELECT() {
        return 'SingleSelect'
    }

    static get MULTI_SELECT() {
        return 'MultiSelect'
    }

    static get TEXT() {
        return 'Text'
    }

    static get FREE_TEXT() {
        return 'FreeText'
    }

    static get NUM_TEXT() {
        return 'NumText'
    }

    static get NUMERIC_TEXT() {
        return 'NumText'
    }

    static get SCI_NOTATION() {
        return 'SciNotation'
    }

    static get PASSWORD(){
        return 'Password'
    }

    static get PHONE_NUMBER(){
        return 'PhoneNumber'
    }
}


export const ItemTypes = (()=> {
    const itemTypes = {
        SECTION: 'Section',
        REQUIRED_SECTION: 'RequiredSection',
        QUESTION: 'Question',
        ANSWER: 'Answer',
    }
    itemTypes.ALWAYS_EXPANDED = new Set([itemTypes.REQUIRED_SECTION.toLowerCase()])
    itemTypes.EXPANDABLE = new Set([
        itemTypes.SECTION.toLowerCase(),
        itemTypes.REQUIRED_SECTION.toLowerCase(),
        itemTypes.QUESTION.toLowerCase(),
    ])
    itemTypes.isExpansion = (x) => {
        return itemTypes.EXPANDABLE.has(`${x}`.toLowerCase())
    }
    return Object.freeze(itemTypes)
})()

export class QuestionnaireProgress {
    static get NOT_STARTED(){
        return 'NOT_STARTED'
    }

    static get IN_PROGRESS(){
        return 'IN_PROGRESS'
    }

    static get FINISHED(){
        return 'FINISHED'
    }

    static get DISABLED(){
        return 'DISABLED'
    }
}

export const AlwaysExpandedItemTypes = new Set([
    ItemTypes.REQUIRED_SECTION.toLowerCase()
])

export const AfsEngineTransactionLogTypes = Object.freeze({
    QUESTIONNAIRE_START: 'QuestionnaireStart',
    QUESTIONNAIRE_END: 'QuestionnaireEnd',
    QUESTIONNAIRE_TIME: 'QuestionnaireTime',
    RESPONSE_LOG: 'ResponseLog',
    GENERAL_LOG: 'GeneralLog',
})