export class PacketFileTypes{
    static get PROOF_SHEET(){return 'proof-sheet'}
    static get META(){return 'meta'}
    static get PACKET(){return 'packet'}
    static get DESCRIPTION(){return 'description'}
    static get BASELINE(){return 'baseline'}
    static get DARK(){return 'dark'}
    static get EQ(){return 'eq'}
    static get INVERSE(){return 'inverse'}
    static get LIGHT(){return 'light'}
}

export class LatentPacketModes{
    static get LATENT(){return 'L'}
    static get EXEMPLAR(){return 'E'}
}
