import Vue from 'vue'
import VueRouter from 'vue-router'
import LoginView from '@/views/LoginView'
import SurveyView from '@/views/SurveyView'
import store from '@/store'
import {AfsVuexNamespaces, General, Survey, UserInfo} from '@/constants/state'
import HomeView from '@/views/HomeView'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Login',
        component: LoginView,
        beforeEnter(to, from, next){
            store.commit(`${AfsVuexNamespaces.General.mapKey}${General.NAV_BAR.setter}`, false)
            next()
        }
    },
    {
        path: '/about',
        name: 'About',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(/* webpackChunkName: "about" */ '../views/AboutView.vue'),
        beforeEnter(to, from, next){
            store.commit(`${AfsVuexNamespaces.General.mapKey}${General.NAV_BAR.setter}`, true)
            next()
        }
    },
    {
        path: '/home',
        name: 'Home',
        component: HomeView,
        beforeEnter(to, from, next){
            store.commit(`${AfsVuexNamespaces.General.mapKey}${General.NAV_BAR.setter}`, true)
            next()
        }
    },
    {
        path: '/survey/:surveyId',
        name: 'Survey',
        component: SurveyView,
        beforeEnter(to, from, next){
            store.commit(`${AfsVuexNamespaces.Surveys.mapKey}${Survey.SURVEY_ID.setter}`, to.params.surveyId)
            store.commit(`${AfsVuexNamespaces.General.mapKey}${General.NAV_BAR.setter}`, !Survey.isRegistration(to.params.surveyId))
            next()
        }
    },
]

const router = new VueRouter({
    routes,

})

router.beforeEach(async (to, from, next) => {
    console.log('ROuTER before each')
    const locStore = store
    locStore.commit(`${AfsVuexNamespaces.User.mapKey}${UserInfo.MFA_ATTEMPTS.setter}`, 0)

    let authRequired = to.name !== 'Login'
    if (to.name === 'Survey' && Survey.isRegistration(to?.params?.surveyId || ''))
        authRequired = false
    let isAuthenticated = (locStore?.getters || {})[`${AfsVuexNamespaces.User.mapKey}${UserInfo.LOGGED_IN.getter}`]
    if (authRequired ){
        console.log(`isAuthenticated: ${isAuthenticated} going from ${from.name} to ${to.name}`)
        if(window.localStorage.tLoadPrev || isAuthenticated) {
            await locStore.dispatch(`${AfsVuexNamespaces.User.mapKey}${UserInfo.SESSION.updater}`)
            isAuthenticated = (locStore?.getters || {})[`${AfsVuexNamespaces.User.mapKey}${UserInfo.LOGGED_IN.getter}`]
        }
        if(isAuthenticated) {
            console.log('Router Authenticated going to original dest')
            next()
        }
        else {
            console.log('Router NOT Authenticated going to Login')
            next({name: 'Login'})
        }
    }
    else {
        next()
    }
})
export default router
