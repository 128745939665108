import {QuestionTypes} from '@/constants/AfsEngineTypes'

class AfsEngineQuestionValidator {
    #questionType = ''

    constructor({
        questionType = '', helpText = '', validation = () => {
            return true
        }
    } = null) {
        this.#questionType = questionType
        this.validation = validation
        this._helpText = helpText
    }

    questionType() {
        return this.#questionType
    }

    get helpText() {
        return this._helpText
    }
}

function stringValueValidator(value, search) {
    const val = typeof value === 'string' ? value : value.text
    if (!val || !val.trim()) return false
    return val.trim().match(search)
}

function passwordValidator(value) {
    // basic password rules
    // length 12
    // one from each
    //    uppper, lower, digit, special
    // Optional spaces
    const minLength = 12
    if (typeof value !== 'string' && !(value instanceof String))
        return false
    if (value.length < minLength)
        return false
    const pattern = value
        .replace(/[A-Z]+/g, 'U')    // replace groups of upper case characters with 'U'
        .replace(/[a-z]+/g, 'l')    // replace groups of lower case characters with 'l'
        .replace(/[0-9]+/g, 'd')    // replace groups of digits with d
        .replace(/\s+/g, 's')       // replace groups of spaces with 's'
        .replace(/[^Ulds]+/g, 'o')  // replace all other characters with 'o'
    const patternContents = new Set(pattern.split('')) // get the set of character classes
    if (patternContents.size < 4)
        return false
    if (patternContents.size === 4 && 's' in patternContents)
        return false
    return true
}

export let AfsEngineQuestionValidators = Object.freeze({
    [QuestionTypes.SINGLE_SELECT.trim().toLowerCase()]: new AfsEngineQuestionValidator({
        questionType: QuestionTypes.SINGLE_SELECT,
        validation: (x) => {
            return !!x
        }
    }),
    [QuestionTypes.MULTI_SELECT.trim().toLowerCase()]: new AfsEngineQuestionValidator({
        questionType: QuestionTypes.MULTI_SELECT,
        validation: (x) => {
            return !!x
        }
    }),
    [QuestionTypes.TEXT.trim().toLowerCase()]: new AfsEngineQuestionValidator({
        questionType: QuestionTypes.TEXT, helpText: 'Enter a value.', validation: (x) => {
            return stringValueValidator(x, /.*/)
        }
    }),
    [QuestionTypes.FREE_TEXT.trim().toLowerCase()]: new AfsEngineQuestionValidator({
        questionType: QuestionTypes.SINGLE_SELECT, helpText: 'Enter a value.', validation: (x) => {
            return stringValueValidator(x, /.*/)
        }
    }),
    [QuestionTypes.NUM_TEXT.trim().toLowerCase()]: new AfsEngineQuestionValidator({
        questionType: QuestionTypes.NUM_TEXT, helpText: 'Enter a number.', validation: (x) => {
            return stringValueValidator(x, /^(-|)[0-9.]+$/)
        }
    }),
    [QuestionTypes.SCI_NOTATION.trim().toLowerCase()]: new AfsEngineQuestionValidator({
        questionType: QuestionTypes.SCI_NOTATION,
        helpText: 'Enter a number using scientific notation: X.XXE+YY or X.XXE-YY',
        validation: (x) => {
            return stringValueValidator(x, /^(-|)[0-9]\.[0-9]+[eE][-+][0-9]+$/)
        }
    }),
    [QuestionTypes.PASSWORD.trim().toLowerCase()]: new AfsEngineQuestionValidator({
        questionType: QuestionTypes.PASSWORD,
        validation: passwordValidator,
        helpText: '12 characters, 1 upper, 1 lower, 1 digit, 1 special'
    }),
})
