<template>
    <v-app>
        <afs-nav-bar v-if="showNav"></afs-nav-bar>
        <v-main :class="mainTopMargin">
            <router-view :class="dynamicClasses" />
        </v-main>
        <afs-snackstand></afs-snackstand>
    </v-app>
</template>

<script>
import AfsSnackstand from '@/components/AfsSnackstand'
import {mapState, mapGetters} from 'vuex'
import {AfsVuexNamespaces, General} from '@/constants/state'
import AfsNavBar from '@/components/AfsNavBar'

export default {
    name: 'App',

    components: {
        AfsNavBar,
        AfsSnackstand
    },
    data: () => {
        return {
            tLoadPrev: false
        }
    },

    computed: {
        ...mapState(AfsVuexNamespaces.General.mapKey, {showNav: General.NAV_BAR}),
        ...mapGetters(AfsVuexNamespaces.Surveys.mapKey, {afsEngineDense: 'afsEngineDense'}),
        pageTitle: function () {
            return this.study
        },
        study: () => {
            return process.env.VUE_APP_AFS_ENGINE_STUDY
        },
        title: () => {
            return process.env.VUE_APP_AFS_ENGINE_TITLE
        },
        mainTopMargin: function(){
            if(this.showNav)
                return 'mt-4'

            return ''
        },
        dynamicClasses: function(){
            let classes = []
            // Taking a shortcut since this is only set for examination questionnaires
            if(this.afsEngineDense)
                classes.push('fill-height')
            return classes.join(' ')
        },
    },
    created: function () {
        document.title = this.pageTitle
        this.tLoadPrev = true

        document.body.dataset.tLoadPrev = true
        window.localStorage.setItem('tLoadPrev', true)
    },

}
</script>

<style lang="scss">
.afs-markdown-gen > p:only-child {
    margin-bottom: 4px;
}
.afs-markdown-gen > ol + p{
  margin-top: 12px;
}
.afs-status-indicator {
  width: 20px;
  max-width: 20px;
  min-width: 20px !important;
}

html {
  overflow-x: auto !important;
}
</style>
