export const AfsEvents = Object.freeze({
    EnabledChanged: 'enabledchanged',
    VALID_CHANGED: 'validchanged',
    ImageScaleChanged: 'imageScaleChanged',
    ImageDimensionsChanged: 'imageDimensionsChanged',
})
// export class AfsEvents {
//     static get EnabledChanged(){
//         return 'enabledchanged'
//     }
//
//     static get VALID_CHANGED(){
//         return 'validchanged'
//     }
//
//     stt
// }