<template>
    <v-container class="align-center mt-0 pt-0 d-block"  v-if="afsEngineDense && !validBrowser">
        <v-row class="text-h3">Unsupported Browser</v-row>
        <v-row class="text-h4">
            This questionnaire requires Chrome or Firefox to function.
        </v-row>
        <v-spacer />
    </v-container>
    <afs-survey v-else />
</template>

<script>
import {AfsVuexNamespaces, Snackstand, Survey} from '@/constants/state'
import {mapActions, mapGetters, mapState} from 'vuex'
import AfsSurvey from '@/components/AfsSurvey'
import AfsSessionRefresh from '@/mixins/afsSessionRefresh'
import Bowser from 'bowser'

export default {
    name: 'SurveyView',
    mixins: [AfsSessionRefresh],
    components: { AfsSurvey },
    props: {
        surveyId: String,
    },
    methods: {
        ...mapActions(AfsVuexNamespaces.Surveys.mapKey, {
            [AfsVuexNamespaces.Surveys.initializer]:
                AfsVuexNamespaces.Surveys.initializer,
        }),
        ...mapActions(AfsVuexNamespaces.Assignments.mapKey, {
            [AfsVuexNamespaces.Assignments.initializer]:
            AfsVuexNamespaces.Assignments.initializer
        }),
        ...mapActions(AfsVuexNamespaces.Snackstand.mapKey, {
           [Snackstand.MESSAGES.insertAction]: Snackstand.MESSAGES.insertAction
        }),
    },
    computed: {
        ...mapState(AfsVuexNamespaces.Surveys.mapKey, [
            Survey.SURVEY_ID,
            Survey.SPEC_VERSION,
            Survey.SURVEY_VERSION,
            Survey.ENTRIES,
            Survey.SURVEY_ITEM_IDS,
            Survey.ENTRY_ORDER_MAP,
        ]),
        ...mapGetters(AfsVuexNamespaces.Surveys.mapKey, {afsEngineDense: 'afsEngineDense'}),
        validBrowser: function(){
            return Bowser.getParser(window.navigator.userAgent)
                .satisfies({chrome: '>70', firefox: '>70'})
        }
    },
}
</script>

<style>
.afs-markdown-gen >>> p:only-child {
    margin-bottom: 4px;
}
</style>
