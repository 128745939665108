import {AfsEngineConditionalOperations, REFERENCE_REGEX_GLOBAL} from '@/constants/AfsEngineConditionalOperations'
import {joinIfValid} from '@/utilities/utils'
import {AfsVuexNamespaces, General, Survey} from '@/constants/state'
import store from '@/store'
import {RefToModule} from '@/classes/ElementReferenceArgs'

export class AfsEngineConditionalOn {
    constructor({
        operator = '',
        operation = '',
        parameter = '',
        value = '',
        currentSurveyId = '',
        currentPacketId = ''
    } = {}) {
        this.operator = operator || operation
        this.parameter = parameter
        this.currentSurveyId = currentSurveyId
        this.currentPacketId = currentPacketId

        if (Array.isArray(value)) {
            this.value = value.map((ele) =>
                ele instanceof Object ? new AfsEngineConditionalOn({
                    currentSurveyId: currentSurveyId,
                    currentPacketId: currentPacketId,
                    ...ele
                }) : ele
            )
            this._references = [
                ...Array.from(this.parameter.matchAll(REFERENCE_REGEX_GLOBAL)).map(x=>x[1]),
                ...this.value.reduce((acc, ele)=>{
                    if (ele instanceof AfsEngineConditionalOn)
                        acc.push(...ele.references)
                    else if (typeof ele === 'string' || ele instanceof String)
                        acc.push(...Array.from(ele.matchAll(REFERENCE_REGEX_GLOBAL)).map(x=>x[1]))
                    return acc
                },[])
            ]
        }
        else if (value instanceof Object) {
            this.value = new AfsEngineConditionalOn({
                currentSurveyId: currentSurveyId,
                currentPacketId: currentPacketId,
                ...value
            })
            this._references = [
                ...Array.from(this.parameter.matchAll(REFERENCE_REGEX_GLOBAL)).map(x=>x[1]),
                ...this.value._references
            ]
        }
        else {
            this.value = value
            this._references = [
                ...Array.from(this.parameter.matchAll(REFERENCE_REGEX_GLOBAL)).map(x=>x[1]),
                ...Array.from(this.value.matchAll(REFERENCE_REGEX_GLOBAL)).map(x=>x[1])
            ]
        }
        this._references = Array.from(new Set(this.references))
    }

    get references() {
        return this._references
    }

    static fromParsed(parsed) {
        const retVal = new AfsEngineConditionalOn(parsed)
        return retVal
    }

    static fromString(val) {
        const retVal = AfsEngineConditionalOn.fromParsed(JSON.parse(val))
        return retVal
    }

    result({items={}, results={}}={}) {
        // short circuit
        if(this.operator === '' && this.parameter === '' && this.value === '')
            return true
        const self = this
        const refLookups = this.references.reduce((acc, ele)=>{
            const parts = ele.split('.')
            const survey =  Survey.isCurrent(parts[0])? self.currentSurveyId: parts[0]
            const itemId = joinIfValid({vals: parts.slice(parts.length > 2? 2: 1)})
            // let resultIdx = Result.generateLookupKey({surveyId: survey, questionId: itemId})
            let resultIdx = store.getters[`${AfsVuexNamespaces.General.mapKey}${General.REF_AS_MODULE.getter}`](
                new RefToModule({
                    questionnaireId: survey,
                    itemId: itemId
                })
            )

            try{
                if(results && results[resultIdx]) {
                    acc[`{${ele}}`] = results[resultIdx]
                }
                else {
                    resultIdx = joinIfValid({vals: [survey, itemId], joinStr: '-'})
                    if(results && results[resultIdx])
                        acc[`{${ele}}`] = results[resultIdx]
                    else
                        acc[`{${ele}}`] = items[itemId].EntryContent
                }
            }
            catch(err){
                console.warn(`err: ${err}\nError computing result: ele: ${JSON.stringify(ele)} itemId: ${itemId}  entry: ${JSON.stringify(items[itemId])}`)
            }
            return acc
        }, {})

        function doReplacement(val, lookup){
            let retVal = []
            if(typeof val === 'string' || val instanceof String)
                retVal = [lookup[val] || val]
            else if(Array.isArray(val))
                retVal = val.map(ele => lookup[ele] || ele)
            retVal = retVal.reduce((acc, ele) => {
                if (ele.Answers) {
                    acc.push(...[...ele.Answers.reduce(ele2 => ele2.AnswerId ? [ele2.AnswerId, ele2.AnswerText] : [ele2.AnswerText])])
                }
                else if (ele.AnswerText) {
                    acc.push(...(ele.AnswerId ? [ele.AnswerId, ele.AnswerText] : [ele.AnswerText]))
                }
                else if (Array.isArray(ele)){
                    acc.push(...[...ele.reduce((acc2, ele2) => {
                        acc2.push(...(ele2.AnswerId ? [ele2.AnswerId, ele2.AnswerText] : [ele2.AnswerText]))
                        return acc2
                    }, [])])
                }
                else {
                    acc.push(`${ele}`)
                }

                return acc
            }, [])

            return retVal
        }

        const parameter = doReplacement(this.parameter, refLookups)
        const value = doReplacement(this.value, refLookups)
        let retVal = false
        const operator = this.operator.trim().toUpperCase()

        switch(operator){
            case AfsEngineConditionalOperations.AND.toUpperCase():
                retVal = this.value.every(ele => ele.result({items: items, results: results}))
                return retVal
            case AfsEngineConditionalOperations.OR.toUpperCase():
                retVal = this.value.some(ele => ele.result({items: items, results: results}))
                return retVal
            case AfsEngineConditionalOperations.IN.toUpperCase():
                retVal = parameter.some(para => value.some(x => x.trim().toLowerCase() === para.trim().toLowerCase()))
                return retVal
            case AfsEngineConditionalOperations.EQUALS.toUpperCase():
            case AfsEngineConditionalOperations.EQUAL.toUpperCase():
            case AfsEngineConditionalOperations.NOT_EQUALS.toUpperCase():
            case AfsEngineConditionalOperations.NOT_EQUAL.toUpperCase():
                if (!parameter || parameter.length === 0)
                    return false
                retVal = parameter.some(para => {
                    if(typeof para === 'undefined')
                        return false
                    if(para.trim)
                        return value.every(x => x.trim().toLowerCase() === para.trim().toLowerCase())
                    return false
                })
                if([AfsEngineConditionalOperations.NOT_EQUALS.toUpperCase(), AfsEngineConditionalOperations.NOT_EQUAL.toUpperCase()].indexOf(operator)>=0) retVal = !retVal
                return retVal
            default:
                console.warn(`Unknown operator ${this.operator}`)
        }
        retVal = true
        return retVal
    }
}
