<template>
    <v-container class="afs-latent-image pa-0 ma-0" ref="latentContainer">
        <img ref="image" :src="imageUrl" class="pa-0 ma-0 d-none" @load="handleImageLoad">
        <canvas ref="background" class="afs-latent-background"
                @click="handleClick" @mousedown="handleMouseDown"
                @mouseup="handleMouseUp" @mousemove="handleMouseMove"/>
<!--        <canvas ref="markup" class="afs-latent-markup" @click="handleClick" @mousedown="handleMouseDown"-->
<!--                @mouseup="handleMouseUp" @mousemove="handleMouseMove"/>-->
        <canvas ref="buffer" class="afs-latent-buffer" :width="bufferDimensions.width" :height="bufferDimensions.height"
                @click="handleClick" @mousedown="handleMouseDown"
                @mouseup="handleMouseUp" @mousemove="handleMouseMove"/>
    </v-container>
</template>

<script>
import {LatentPacketModes, PacketFileTypes} from '@/constants/PacketFileTypes'
import {mapGetters, mapState} from 'vuex'
import {AfsVuexNamespaces, General, PacketAssignment, Survey} from '@/constants/state'
import {afsApi} from '@/utilities/api'
import {LatentToolMode} from '@/constants/LatentToolMode'
import {AfsEvents} from '@/constants/events'

export default {
    name: 'LatentImage',
    props: {
        // scale: {
        //     type: Number,
        //     default: 1,
        // },
        zoomTimes: {
            type: Number,
            default: 1,
        },
        rotationSteps: {
            type: Number,
            default: 0,
        },
        maxRotationSteps: {
            type: Number,
            default: 16,
        },
        toolMode: {
            type: String,
            default: LatentToolMode.HAND
        },
        latentImageType: {
            type: String,
            default: PacketFileTypes.BASELINE
        },
        latentPacketMode: {
            type: String,
            default: LatentPacketModes.LATENT
        },
        showMarkup: {
            type: Boolean,
            default: true,
        },
        sharedScale: {
            type: Number,
            default: 1,
        },
        otherImageDimensions: {
            type: Object,
            default: () => ({width: 0, height: 0})
        }
    },
    data: () => {
        return {
            // imageAspectRatio: 1,
            imageDimensions: { // raw pixel dimensions of the actual image
                width: 0,
                height: 0,
            },
            displayDimensions: { // screen pixels
                width: 0,
                height: 0
            },
            // bufferDimensions: { // buffer pixels
            //     width: 0,
            //     height: 0
            // },
            // maxDimensions: {
            //     width: 0,
            //     height: 0,
            // },
            imageMarks: [],
            // displayMarks: [],
            translation: {
                x: 0,
                y: 0,
            },
            panned: {
                x: 0,
                y: 0,
            },
            lastPanned: {
                x: 0,
                y: 0
            },
            mouseDown: false,
            panStart: {
                x: 0,
                y: 0,
            },
            backgroundContext: {},
            markupContext: {},
            bufferContext: {},
            imageUrl: '',
            markRadius: 6,
            minMarkRadius: 2,
            bufferScale: 1.7,
        }
    },
    computed: {
        ...mapState(AfsVuexNamespaces.Surveys.mapKey, {
            [Survey.SURVEY_ID]: Survey.SURVEY_ID,
        }),
        ...mapGetters(AfsVuexNamespaces.Surveys.mapKey, {[Survey.RESPONSES]: Survey.RESPONSES.getter}),
        ...mapState(AfsVuexNamespaces.Assignments.mapKey, [PacketAssignment.CURRENT_INDEX, PacketAssignment.ASSIGNMENT_ID]),
        ...mapGetters(AfsVuexNamespaces.Assignments.mapKey, {
            [PacketAssignment.CURRENT]: PacketAssignment.CURRENT.getter,
            [PacketAssignment.NUM_PACKETS]: PacketAssignment.NUM_PACKETS.getter,
        }),
        ...mapGetters(AfsVuexNamespaces.General.mapKey, {[General.REF_AS_MODULE]: General.REF_AS_MODULE.getter}),
        degreesPerStep: function () {
            return 360 / this.maxRotationSteps
        },
        rotationDegrees: function () {
            return this.rotationSteps * this.degreesPerStep
        },
        rotationRadians: function () {
            return this.rotationDegrees * Math.PI / 180
        },
        zoomFactor: function () {
            return 1.5 // MAGIC VALUE
        },
        rawScale: function(){
            return this.displayDimensions.width / (this.imageDimensions.width || this.displayDimensions.width || 1)
        },
        zoom: function(){
            return Math.pow(this.zoomFactor, this.zoomTimes)
        },
        scale: function () {
            return this.zoom * this.sharedScale
        },
        imageAspectRatio: function(){
            return  this.imageDimensions.height / this.imageDimensions.width
        },
        workingDimensions: function () {
            const width = this.imageDimensions.width
            const retVal = {
                width: Math.floor(width),
                height: Math.floor(Math.min(width * this.displayAspectRatio, this.imageDimensions.height)),
            }

            return retVal
        },
        rotationDimensions: function(){
            const rotated = this.calcRawRotation(this.imageDimensions)
            return {
                width: Math.max(this.workingDimensions.width, this.workingDimensions.width + Math.ceil(Math.abs(this.workingDimensions.width - rotated.x))),
                height: Math.max(this.workingDimensions.height, this.workingDimensions.height + Math.ceil(Math.abs(this.workingDimensions.height - rotated.x))),
            }
        },
        workingOffset: function(){
            return {
                x: this.imageCenter.x - this.workingCenter.x,
                y: this.imageCenter.y - this.workingCenter.y
            }
        },
        imageCenter: function(){
            return {
                x: this.imageDimensions.width/2,
                y: this.imageDimensions.height/2
            }
        },
        displayCenter: function () {
            return {
                x: this.displayDimensions.width / 2,
                y: this.displayDimensions.height / 2,
            }
        },
        bufferDimensions: function (){
            return {
                width: this.imageDimensions.width * this.bufferScale,
                height: this.imageDimensions.height * this.bufferScale,
            }
        },
        bufferCenter: function(){
            return {
                x: this.bufferDimensions.width / 2,
                y: this.bufferDimensions.height / 2,
            }
        },
        bufferImageTopLeft: function() {
            const offset =  {
                x: this.bufferCenter.x - this.imageCenter.x,
                y: this.bufferCenter.y - this.imageCenter.y,
            }

            return {
                x: Math.min(offset.x),
                y: Math.min(offset.y),
            }
        },
        centeringOffset: function(){
            return {
                x: 0,
                y: 0,
            }
        },
        displayAspectRatio: function() {
            return this.displayDimensions.height/this.displayDimensions.width
        },
        workingCenter: {
            get: function (){
                return {
                    x: this.workingDimensions.width / 2,
                    y: this.workingDimensions.height / 2,
                }
            }
        },
        maxDimensions: function(){
            return {
                width: Math.floor(this.displayDimensions.width/this.scale),
                height: Math.floor(this.displayDimensions.height/this.scale)
            }
        },
        backgroundTranslate: function () {
            const dc = this.displayCenter
            return {
                x: dc.x - this.panned.x,
                y: dc.y - this.panned.y,
            }
        },
        maxWidth: function(){
            return this.$refs.latentContainer.clientWidth
        },
        maxHeight: function(){
            return this.$refs.latentContainer.clientHeight
        },
        displayMarks: {
            get: function(){
                const self = this
                const retVal = this.imageMarks.map(function (ele){
                    return self.imagePositionToScreenPosition(ele)
                })
                return retVal
            }
        }
    },
    methods: {
        calcRawRotation(pt, dir = 1){
            const x = pt.x || pt.width
            const y = pt.y || pt.height
            const angle = dir*this.rotationRadians
            const cos = Math.cos(angle)
            const sin = Math.sin(angle)
            return {
                x: Math.abs(Math.floor(x * cos - y * sin)),
                y: Math.abs(Math.floor(x * sin - y * cos))
            }
        },
        handleClick: function (event) {
            const imgPt = this.screenPositionToImagePosition({x: event.offsetX, y: event.offsetY})
            if(!this.showMarkup)
                return
            // let index = -1
            switch(this.toolMode.trim().toLowerCase()){
                case LatentToolMode.DOT.toLowerCase():
                    this.imageMarks.push(imgPt)
                    break
                case LatentToolMode.DEL.toLowerCase(): {
                    const index = this.imageMarks.findIndex(ele =>
                        Math.sqrt(Math.pow(imgPt.x - ele.x, 2) + Math.pow(imgPt.y - ele.y, 2)) < this.markRadius
                    )
                    if (index >= 0)
                        this.imageMarks.splice(index, 1)
                }
            }
        },
        handleMouseDown: function (event) {
            if (this.toolMode.trim().toLowerCase() !== LatentToolMode.HAND.toLowerCase()) {
                // console.log(`handleMouseDown: '${this.toolMode.trim().toLowerCase()}' !== '${LatentToolMode.HAND.toLowerCase()}'`)
                return
            }
            this.mouseDown = true
            this.panStart = {
                x: event.offsetX,
                y: event.offsetY,
            }
        },
        handleMouseMove: function (event) {
            if (this.toolMode.trim().toLowerCase() !== LatentToolMode.HAND.toLowerCase())
                return
            if (!this.mouseDown)
                return

            this.panned = {
                x: this.panStart.x - event.offsetX + this.lastPanned.x,
                y: this.panStart.y - event.offsetY + this.lastPanned.y,
            }
        },
        handleMouseUp: function () {
            if (this.toolMode.trim().toLowerCase() !== LatentToolMode.HAND.toLowerCase())
                return
            if(!this.mouseDown)
                return
            this.mouseDown = false
            this.lastPanned = {
                x: this.panned.x,
                y: this.panned.y
            }
            this.panStart = {x: 0, y: 0}
        },
        handleImageLoad: function () {
            this.imageDimensions = {
                width: this.$refs.image.width,
                height: this.$refs.image.height,
            }
            this.redraw()
        },
        handleResize: function(){
            if(!this.$refs.latentContainer)
                return true
            console.log('resizing')
            this.displayDimensions = {
                width: this.$refs.latentContainer.clientWidth,
                height: this.$refs.latentContainer.clientHeight,
            }
            return true
        },
        drawBuffer: function () {
            if(this.workingDimensions.width < 1 ||  this.workingDimensions.height < 1)
                return

            this.bufferContext.clearRect(0, 0, this.bufferDimensions.width, this.bufferDimensions.height)
            this.bufferContext.save()

            this.bufferContext.scale(1 ,1)
            this.bufferContext.translate(this.bufferCenter.x, this.bufferCenter.y)
            this.bufferContext.rotate(this.rotationRadians)

            this.bufferContext.drawImage(
                this.$refs.image,
                0, 0,
                this.imageDimensions.width, this.imageDimensions.height,
                -this.imageCenter.x, -this.imageCenter.y,
                this.imageDimensions.width, this.imageDimensions.height,
            )

            // // draw marks
            // if(this.showMarkup) {
            //     const self = this
            //     this.imageMarks.forEach(function (ele) {
            //         console.log(`self === this: ${self === this}`)
            //         self.bufferContext.lineCap = 'round'
            //         self.bufferContext.lineJoin = 'round'
            //         self.bufferContext.lineWidth = 2
            //         self.bufferContext.strokeWidth = 2
            //
            //         self.bufferContext.fillStyle = 'yellow'
            //         self.bufferContext.strokeStyle = 'black'
            //         self.bufferContext.beginPath()
            //         self.bufferContext.arc(ele.x - self.imageCenter.x, ele.y - self.imageCenter.y, 8, 0, Math.PI * 2, true)
            //         self.bufferContext.fill()
            //         self.bufferContext.stroke()
            //     })
            // }
            this.bufferContext.restore()
        },
        drawBackground: function() {
            if(this.bufferDimensions.width <= 0 || this.bufferDimensions.height <= 0)
                return
            if(this.$refs.buffer.width <= 0 || this.$refs.buffer.height <= 0){
                this.$refs.buffer.width = this.bufferDimensions.width
                this.$refs.buffer.height = this.bufferDimensions.height
            }
            this.backgroundContext.save()
            this.backgroundContext.clearRect(0, 0, this.displayDimensions.width, this.displayDimensions.height)
            this.backgroundContext.scale(this.scale, this.scale)

            const srcPan = {
                x: this.panned.x/this.scale,
                y: this.panned.y/this.scale
            }
            const destPan = {
                x: 0,
                y: 0,
            }
            const src = {
                x: this.bufferImageTopLeft.x + srcPan.x,
                y: this.bufferImageTopLeft.y + srcPan.y,
                width: this.workingDimensions.width + Math.max(this.maxDimensions.width - this.workingDimensions.width),
                height: this.workingDimensions.height,
            }
            src.height = src.width * this.displayAspectRatio
            const dest = {
                x: destPan.x,
                y: destPan.y,
                width: src.width,
                height: src.height,
            }
            // console.log(`src: (${src.x}, ${src.y})[${src.width}, ${src.height}]{${src.height/src.width}}  dest: (${dest.x}, ${dest.y})[${dest.width}, ${dest.height}]{${dest.height/dest.width}}`)
            // console.log(`scaled: [${this.displayDimensions.width / this.scale},${this.displayDimensions.height/this.scale}] {${this.displayAspectRatio}`)
            this.backgroundContext.drawImage(
                this.$refs.buffer,
                src.x, src.y, src.width, src.height,
                dest.x, dest.y, dest.width, dest.height,
            )

            this.backgroundContext.restore()

            // draw marks
            if(this.showMarkup) {
                const self = this
                const markRadius = Math.max(this.minMarkRadius, this.markRadius*this.zoom)
                this.displayMarks.forEach(function (ele) {
                    self.backgroundContext.lineCap = 'round'
                    self.backgroundContext.lineJoin = 'round'
                    self.backgroundContext.lineWidth = 2
                    self.backgroundContext.strokeWidth = Math.max(2, 2 * self.zoom)

                    self.backgroundContext.fillStyle = 'lime'
                    self.backgroundContext.strokeStyle = 'black'
                    self.backgroundContext.beginPath()
                    self.backgroundContext.arc(ele.x, ele.y, markRadius, 0, Math.PI * 2, true)
                    self.backgroundContext.fill()
                    self.backgroundContext.stroke()
                })
            }
        },
        redraw: function () {
            this.drawBuffer()
            this.drawBackground()
        },
        reset: function(){
            this.panned = {x: 0, y: 0}
            this.panStart = {x: 0, y: 0}
            this.lastPanned = {x: 0, y: 0}
        },

        // workarounds for lack of async computed
        getImageUrl: async function () {
            const imageUrl = await afsApi.getLatentPacket(this[Survey.SURVEY_ID], this.latentImageType,
                                                          this[PacketAssignment.ASSIGNMENT_ID], this[PacketAssignment.CURRENT]?.Packet,
                                                          this.latentPacketMode)

            this.imageUrl = imageUrl
        },
        screenToBuffer: function(pt){
            const x = (pt.x * this.scale)
            const y = (pt.y * this.scale)
            const angle = this.rotationRadians * -1
            const cos = Math.cos(angle)
            const sin = Math.sin(angle)
            return {
                x: Math.floor(x * cos - y * sin),
                y: Math.floor(x * sin + y * cos)
            }
        },
        imagePositionToScreenPosition: function(pt){
            const x = (pt.x * this.scale)
            const y = (pt.y * this.scale)
            const scCtr = {
                x: Math.floor(this.imageCenter.x * this.scale) + this.centeringOffset.x,
                y: Math.floor(this.imageCenter.y * this.scale) + this.centeringOffset.y,
            }
            const angle = this.rotationRadians //* -1
            const cos = Math.cos(angle)
            const sin = Math.sin(angle)
            return {
                x: Math.floor((x - scCtr.x) * cos - (y - scCtr.y) * sin) + scCtr.x - this.panned.x,
                y: Math.floor((x - scCtr.x) * sin + (y - scCtr.y) * cos) + scCtr.y - this.panned.y,
            }
        },
        screenPositionToImagePosition: function(pt) {
            const x = (pt.x + this.panned.x) /this.scale
            const y = (pt.y + this.panned.y) /this.scale
            const angle = -this.rotationRadians
            const cos = Math.cos(angle)
            const sin = Math.sin(angle)
            const adjCtr = {
                x: this.imageCenter.x + this.centeringOffset.x,
                y: this.imageCenter.y + this.centeringOffset.y,
            }
            return {
                x: Math.floor((x - adjCtr.x) * cos - (y - adjCtr.y) * sin) + adjCtr.x,
                y: Math.floor((x - adjCtr.x) * sin + (y - adjCtr.y) * cos) + adjCtr.y,
            }
        },
    },
    watch: {
        imageUrl: function(newVal){
            if(!newVal) return
            this.redraw()
        },
        [Survey.SURVEY_ID]: async function () {
            const promises = [
                this.getImageUrl()
            ]
            await Promise.all(promises)
        },
        [PacketAssignment.ASSIGNMENT_ID]: async function () {
            const promises = [
                this.getImageUrl()
            ]
            await Promise.all(promises)
        },
        [PacketAssignment.CURRENT]: async function () {
            const promises = [
                this.getImageUrl()
            ]
            await Promise.all(promises)
        },
        rotationRadians: function(){
            this.redraw()
        },
        latentImageType: async function () {
            const promises = [
                this.getImageUrl()
            ]
            await Promise.all(promises)
        },
        rawScale: function(){
            this.$emit(AfsEvents.ImageScaleChanged, {
                mode: this.latentPacketMode,
                scale: this.rawScale
            })
        },
        scale: function(){
            this.redraw()
        },
        panned: function () {
            this.redraw()
        },
        imageMarks: function(){
            this.redraw()
        },
        showMarkup: function(){
            this.redraw()
        },
        imageDimensions: function(){
            this.redraw()
        },
        displayDimensions: function(){
            if(this.$refs.background){
                this.$refs.background.width = this.displayDimensions.width
                this.$refs.background.height = this.displayDimensions.height
            }
        },
        workingDimensions: function(){
            this.redraw()
        },
    },
    mounted() {
        this.backgroundContext = this.$refs?.background?.getContext('2d')
        this.markupContext = this.$refs?.markup?.getContext('2d')
        this.bufferContext = this.$refs?.buffer?.getContext('2d')
        this.getImageUrl()

        this.displayDimensions = {
            width: this.$refs.latentContainer.clientWidth,
            height: this.$refs.latentContainer.clientHeight,
        }
        window.addEventListener('resize', this.handleResize)
        // this.redraw()
        // const self = this
        // setTimeout(()=>{
        //     console.log(`mode: ${this.latentPacketMode} wait redraw`)
        //     self.redraw()
        // }, 2500)
    },
}
</script>

<style scoped>

.afs-latent-image {
    width: 50%;
    height: 100%;
    display: inline-flex;
    outline: black 1px solid;
}

.afs-latent-image > img {
    visibility: hidden;
}

canvas {
    position: absolute;
    display: block;
    margin: 0;
    padding: 0;
    /*width: 100%;*/
    /*height: 100%*/
}

canvas.afs-latent-background {
    z-index: 3;
}

canvas.afs-latent-markup {
    /*z-index: 4;*/
}

canvas.afs-latent-buffer {
    display: none;
    visibility: hidden;
}
</style>