<template>
    <v-dialog
            v-model="dialog"
            fullscreen
            hide-overlay
            transition="dialog-bottom-transition"
    >
        <v-card class="px-10">
            <v-row>
                <div class="col text-center">
                    <h1>We’re Sorry-- We’ve Run into a Problem!</h1>
                    <h4 class="text-secondary">Response Error Encountered</h4>
                </div>
            </v-row>
            <v-row>
                <v-col>
                </v-col>
            </v-row>

            <v-spacer/>

            <p class="text-center">There was a problem with one of your responses and you can no longer proceed.</p>
            <p class="text-center">
                If you are seeing this page while completing the Registration or Background Questionnaire, one of your
                responses indicated that you do not meet the eligibility requirements for this study.
            </p>
            <p class="text-center">
                If you are seeing this page while attempting to login to the study website (during password or verification
                prompt entry), there was an issue with your response. The password or verification code was entered incorrectly
                5 or more times. Please contact the study team to reset your account.
            </p>
            <p class="text-center">If you believe you are seeing this page in error, please contact the study team for assistance.</p>
            <p class="text-center">Study team contact: <a :href="`${studyEmail}`">{{ studyEmail }}</a></p>
            <div class="col text-center">
                <a href="/" class="btn btn-primary">Return To Login</a>
            </div>
        </v-card>
    </v-dialog>
</template>

<script>
import {mapState} from 'vuex'
import {AfsVuexNamespaces, Survey} from '@/constants/state'

export default {
    name: 'AfsErrorRedirect',
    props: {
        studyEmail: {
            type: String,
            default: process.env.VUE_APP_AFS_ENGINE_STUDY_EMAIL
        }
    },
    data: () => {
        return {
            dialog: false,
        }
    },
    computed: {
        ...mapState(AfsVuexNamespaces.Surveys.mapKey, [Survey.IS_TERMINATING]),
    },
    watch: {
        [Survey.IS_TERMINATING]: function (val) {
            if (val) this.dialog = true
        }
    }
}
</script>

<style scoped>

</style>